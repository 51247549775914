import { acceptHMRUpdate, defineStore } from 'pinia'
import googleCalendarAPI from '@/apis/googleCalendar.js'
import { useGlobalStore } from '@/stores/useGlobalStore.js'
import { Browser } from '@capacitor/browser'
import { address } from '@/utils/index.js'
import { useCalendar } from '@/composables/useCalendar.js'

const { generateDateArray } = useCalendar()

export const useCalendarStore = defineStore('calendar', {
  state: () => ({
    dateArray: [],
    eventsData: [],
    google: {
      access_token: '',
      refresh_token: '',
      expires_in: 0,
      calendars: []
    }
  }),

  getters: {
    selectedCalendars (state) {
      return state.google.calendars.filter(calendar => calendar.selected === true)
    }
  },

  actions: {
    /**
     * Authenticates user with Google.auth.OAuth2 using a cloud function
     * @return {Promise<void>}
     */
    async authenticate (uid) {
      localStorage.setItem('uid', uid)

      try {
        const response = await fetch('https://us-central1-tradebox-2678f.cloudfunctions.net/googleFetchAuthURL')
        const url = await response.text()
        await Browser.open({ url: url, windowName: '_self' })
      } catch (e) {
        throw new Error('Error fetching auth URL' + e)
      }
    },

    /**
     * Refreshes users access token for Google calendar access
     * @return {Promise} Access token object including expires_in date
     */
    async refreshAccessToken (refreshToken) {
      refreshToken = refreshToken || this.google.refresh_token
      if (refreshToken && (!this.google.expires_in || this.google.expires_in < 3000)) { // 3000 seconds - 5 Min
        try {
          const token = await googleCalendarAPI.refreshAccessToken(refreshToken)
          const { access_token, expires_in } = token
          this.google.expires_in = expires_in
          this.google.access_token = access_token
          return { access_token, expires_in }
        } catch (e) {
          console.log('refresh Access Token', e)
        }
      }
    },

    /**
     * Fetch google calendars
     * @param accessToken
     * @return {Promise} List of Google calendars attached to users account
     */
    async getCalendars (accessToken) {
      accessToken = accessToken || this.google.access_token
      if (accessToken && !this.google.calendars.length && this.google.expires_in) {
        try {
          const calendars = await googleCalendarAPI.getCalendars(accessToken)
          this.google.calendars = calendars
          return calendars
        } catch (error) {
          console.error('Error fetching users calendars:', error)
        }
      }
    },

    /**
     * Updates the events in the dateArray object based on the fetched events data.
     * @param {number} year - The year of the calendar.
     * @param {number} month - The month of the calendar (0-indexed).
     * @param {string} duration - The duration of the calendar (e.g., 'Month', 'Week').
     * @param {string} accessToken - The access token for authentication.
     * @param {Date} weeklyStartDate - The start date of the week (used for weekly view).
     * @returns {Promise<void>} A Promise that resolves when the update is completed.
     */
    async updateEventsInDateArray (year, month, duration, accessToken, weeklyStartDate) {
      const { access_token, expires_in } = this.google
      if (access_token && expires_in) {
        await this.fetchEvents(
          year,
          month,
          duration,
          access_token,
          weeklyStartDate
        )
      }

      for (const dateObj of this.dateArray) {
        const currentDate = new Date(dateObj.date)

        if (this.eventsData.length) {
          const dayEvents = this.eventsData.filter((event) => {
            const eventStartDate = new Date(event?.start?.dateTime?.split('T')[0] || event?.start?.date)
            const eventEndDate = new Date(event?.end?.dateTime?.split('T')[0] || event?.end?.date)
            const currentDateWithoutTime = new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              currentDate.getDate()
            )

            // Include events that intersect with the current date
            return (
              eventStartDate <= currentDate &&
              eventEndDate >= currentDateWithoutTime
            )
          })

          dateObj.events = dayEvents.map((event) => {
            const isAllDay = event.start.date && !event.start.dateTime
            const eventStartDate = new Date(event.start.dateTime || event.start.date)
            const eventEndDate = new Date(event.end.dateTime || event.end.date)
            const eventEndDateWithoutTime = new Date(
              eventEndDate.getFullYear(),
              eventEndDate.getMonth(),
              eventEndDate.getDate()
            )
            const dayDifference = Math.ceil((eventEndDate - eventStartDate) / (1000 * 60 * 60 * 24))
            const isMultiDay = dayDifference > 1
            const isFirstDay = this.isSameDay(eventStartDate, currentDate)
            const isLastDay =
              this.isSameDay(eventEndDate, currentDate) ||
              (isMultiDay && this.isSameDay(eventEndDateWithoutTime, currentDate))
            const eventCalendarId = event.organizer.email || event.creator.email || 'primary'
            const calendar = this.selectedCalendars.find((cal) => cal.id === eventCalendarId)
            const backgroundColor = calendar ? calendar.backgroundColor : null

            return {
              id: event.id,
              summary: event.summary,
              description: event.description,
              time: isAllDay
                ? 'All day'
                : new Date(event.start.dateTime || event.start.date).toLocaleTimeString([], {
                  hour: 'numeric',
                  minute: '2-digit'
                }),
              datetime: event.start.dateTime || event.start.date,
              endTime: event.end.dateTime || event.end.date,
              href: event.htmlLink,
              attendees: event.attendees,
              reminders: event.reminders,
              isMultiDay,
              isFirstDay,
              isLastDay,
              backgroundColor,
              ...event,
              location: event.location ? this.parseAddress(event.location) : address
            }
          })
        }
      }
    },

    parseAddress (addressString) {
      const location = { ...address }
      // Split the address string by commas
      const parts = addressString.split(', ')

      // Check if the parts array has at least three elements
      if (parts.length >= 3 && !isNaN(parts[2].split(' ')[1])) {
        // Assign values to the corresponding variables
        const [state, zip] = parts[2].split(' ')
        location.street = parts[0]
        location.city = parts[1]
        location.state = state
        location.zip = zip
        location.country = parts[3]
        location.formatted = addressString
      } else {
        // Obscure address format, assign the whole address string
        location.formatted = addressString
      }

      return location
    },

    /**
     * Generates a weekly data array
     * @param year
     * @param month
     * @param duration
     * @param accessToken
     * @param weeklyStartDate
     * @return {Promise<*[]>}
     */
    async generateWeeklyDateArray (year, month, duration, accessToken, weeklyStartDate) {
      // set defaults
      year = year || new Date().getFullYear()
      month = month || new Date().getMonth()
      duration = duration || 'Week'
      accessToken = accessToken || this.google.access_token
      weeklyStartDate = weeklyStartDate ? new Date(weeklyStartDate) : this.getStartOfWeek(new Date())

      const dateArray = []
      // fetch events
      if (accessToken && this.google.expires_in) {
        await this.fetchEvents(year, month, duration, accessToken, weeklyStartDate)
      }

      for (let i = 0; i < 7; i++) {
        const fullDate = new Date(weeklyStartDate.getFullYear(), weeklyStartDate.getMonth(),
          weeklyStartDate.getDate() + i)
        const eventsOnDate = this.getEventsOnDate(fullDate, this.eventsData)
        const formattedEvents = eventsOnDate.map(event => this.formatEventWeek(event))

        dateArray.push({
          date: fullDate.getDate(),
          fullDate: fullDate,
          dayOfWeek: this.getDayOfWeek(i),
          isToday: this.isSameDay(new Date(), fullDate),
          isSelected: false,
          events: formattedEvents
        })
      }

        this.dateArray = dateArray
        return dateArray

    },

    // Helper function to get the day of the week
    getDayOfWeek (dayIndex) {
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      return daysOfWeek[dayIndex]
    },

    // Helper function to check if two dates are the same day
    isSameDayWeek (date1, date2) {
      return date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear() &&
        date1.getDay() === date2.getDay()
    },

    // Helper function to get events on a specific date
    getEventsOnDate (date, events) {
      return events.filter(event => this.isSameDayWeek(new Date(event.start.dateTime || event.start.date), date))
    },

    // Helper function to format an event for display
    formatEventWeek (event) {
      const eventCalendarId = event.organizer.email || event.creator.email || 'primary'
      const calendar = this.selectedCalendars.find(cal => cal.id === eventCalendarId)
      const backgroundColor = calendar ? calendar.backgroundColor : null

      return {
        id: event.id,
        title: event.title,
        start: new Date(event.start),
        end: new Date(event.end),
        description: event.description,
        location: event.location,
        backgroundColor,
        ...event
      }
    },

    /**
     * Asynchronously fetches events from the Google Calendar API based on the provided parameters.
     * @param {number} year - The year for which to fetch events.
     * @param {number} month - The month for which to fetch events.
     * @param {string} duration - The duration for which to fetch events (either 'Week' or 'Month').
     * @param {string} accessToken - The user's Google Calendar API access token.
     * @param {Date} weeklyStartDate - The start date of the week to fetch events (if duration is 'Week').
     * @returns {Promise<Array>} A promise that resolves with an array of events fetched from the Google Calendar API.
     * @throws {Error} An error is thrown if the duration parameter is invalid.
     */
    async fetchEvents (year, month, duration, accessToken, weeklyStartDate) {
      // set defaults
      year = year || new Date().getFullYear()
      month = month || new Date().getMonth()
      duration = duration || 'Month'
      accessToken = accessToken || this.google.access_token
      let startDate, endDate

      if (duration === 'Week') {
        startDate = weeklyStartDate ? new Date(weeklyStartDate) : this.getStartOfWeek(new Date())
        endDate = new Date(startDate.getTime() + 6 * 86400000)
      } else if (duration === 'Month') {
        startDate = new Date(year, month, 1)
        endDate = new Date(year, month + 1, 0)
      } else {
        throw new Error('Invalid duration parameter')
      }


      try {
        if (accessToken) {
          this.eventsData = await googleCalendarAPI.fetchEvents(accessToken, this.selectedCalendars,
            startDate.toISOString(), endDate.toISOString())
        }
      } catch (error) {
        throw new Error('fetchEvents error' + error)
      }
      return this.eventsData
    },

    /**
     * Gets the start date of the current week.
     * @returns {Date} The start date of the week.
     */
    getStartOfWeek (date) {
      const today = new Date(date)
      return new Date(today.setDate(today.getDate() - today.getDay()))
    },

    /**
     * Checks if two dates are the same day.
     * @returns {boolean} - Whether the two dates are the same day.
     */
    isSameDay (date1, date2) {
      return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
      )
    },

    /**
     * Add Event to Google calendar
     * @param year
     * @param month
     * @param duration
     * @param event
     * @param calendarId
     * @param weeklyStartDate
     * @return {Promise<{data, success: boolean}>}
     */
    async addEventToCalendar (year, month, duration, event, calendarId = 'primary', weeklyStartDate) {
      const globalStore = useGlobalStore()
      try {
        let newEvent

        if (event.id) {
          newEvent = await googleCalendarAPI.updateEvent(this.google.access_token, calendarId, event.id, event)
          const day = this.dateArray.find((day) => day.date === newEvent.start.date)

          if (day) {
            const index = day.events.findIndex((e) => e.id === newEvent.id)
            if (index !== -1) {
              day.events.splice(index, 1, newEvent)
            }
          }

          if (duration === 'Month') {
            // await generateDateArray(year, month, duration, this.google.access_token, true)
            await this.updateEventsInDateArray(year, month, duration, this.google.access_token, null)
          } else {
            // await this.generateWeeklyDateArray(year, month, duration, this.google.access_token, weeklyStartDate)
            await this.updateEventsInDateArray(year, month, duration, this.google.access_token, weeklyStartDate)
          }

        } else {
          newEvent = await googleCalendarAPI.addEvent(this.google.access_token, calendarId, event)
          this.eventsData.push(newEvent)
          if (duration === 'Month') {
            // await generateDateArray(year, month, duration, this.google.access_token, true)
            await this.updateEventsInDateArray(year, month, duration, this.google.access_token, null)
          } else {
            // await this.generateWeeklyDateArray(year, month, duration, this.google.access_token, weeklyStartDate)
            await this.updateEventsInDateArray(year, month, duration, this.google.access_token, weeklyStartDate)
          }
        }

        globalStore.openNotify(
          'success',
          'Success',
          'Your Event has been successfully saved.'
        )
        return { success: true, data: event }
      } catch (error) {
        globalStore.openNotify(
          'error',
          'Error',
          error.message
        )
        return { success: false, data: error }
      }
    },

    /**
     * Remove event from calendar
     * @return {Promise<{success: boolean}>}
     */
    async removeEventFromCalendar (event) {
      const globalStore = useGlobalStore()
      try {
        const calendarId = 'primary' // Use 'primary' for the user's primary calendar
        await googleCalendarAPI.removeEvent(this.google.access_token, calendarId, event.id)

        // Find the day in the days array and remove the event
        for (const day of this.dateArray) {
          const eventIndex = day.events.findIndex((item) => item.id === event.id)
          if (eventIndex !== -1) {
            day.events.splice(eventIndex, 1)
          }
        }
        globalStore.openNotify(
          'success',
          'Success',
          'Your Event has been successfully removed.'
        )
        return { success: true }
      } catch (error) {
        globalStore.openNotify(
          'error',
          'Error',
          error.message
        )
      }
    },

  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCalendarStore, import.meta.hot))
}

