<script setup>
import {onUnmounted} from 'vue'
import {useGlobalStore} from '@/stores/useGlobalStore.js'
import {CheckCircleIcon, ExclamationCircleIcon} from '@heroicons/vue/24/outline'
import {usePlatform} from '@/composables/usePlatform.js'
import ButtonClose from '@/components/ButtonClose.vue'

const useGlobal = useGlobalStore()

const unsubscribe = useGlobal.$onAction(
  ({ name, after, onError }) => {
    after((result) => {
      if (name === 'openNotify' && useGlobal.notifications?.length) {
        setTimeout(() => {
          useGlobal.notifications[result.index].show = false
        }, result.notification.timeout)
      }
    })

    onError((error) => {
      console.warn(`Failed "${name}" \nError: ${error}.`)
    })
  }
)

onUnmounted(() => {
  unsubscribe()
})
</script>

<template>
  <!-- Global notification, render this permanently at the end of App.vue -->
  <div
    aria-live="assertive"
    :class="usePlatform.isCapacitor && usePlatform.isApple ? 'safe-padding px-4 py-6 sm:p-6': usePlatform.isAndroid ? 'px-4 py-6 sm:p-6' : 'px-4 py-6 sm:p-6'"
    class="pointer-events-none fixed inset-0 z-50 flex items-start"
  >
    <div class="flex w-full flex-col space-y-4 items-center">
      <!-- Notification panel, dynamically controlled by useGlobalStore -->
      <template
        v-for="(item, index) in useGlobal.notifications"
        :key="item.id"
      >
        <transition
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="item.show"
            :class="item.type === 'success' ? 'shadow-green-600/20 dark:shadow-green-700/30' : item.type === 'error' ? 'shadow-red-600/20 dark:shadow-red-700/30' : null"
            class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white dark:bg-gray-950 shadow-lg border border-gray-900/10 dark:border-white/10"
          >
            <div
              class="p-4 border-gray-900/10 dark:border-white/10"
            >
              <div class="flex items-start">
                <!-- icon -->
                <div class="flex-shrink-0">
                  <CheckCircleIcon
                    v-if="item.type === 'success'"
                    aria-hidden="true"
                    class="h-6 w-6 text-green-500"
                  />

                  <ExclamationCircleIcon
                    v-if="item.type === 'error'"
                    aria-hidden="true"
                    class="h-6 w-6 text-red-500"
                  />
                </div>

                <!-- Title / Text -->
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-sm font-medium text-gray-900 dark:text-white">
                    {{ item.title }}
                  </p>
                  <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                    {{ item.text }}
                  </p>
                </div>

                <!-- Action -->
                <div class="ml-4 flex flex-shrink-0">
                  <button-close
                    @click="useGlobal.closeNotify(index)"
                  />
                </div>
              </div>
            </div>
          </div>
        </transition>
      </template>
    </div>
  </div>
</template>
