import { ref } from 'vue'

export const useValidate = () => {
  const errors = ref([])
  const isError = ref(false)
  const errorMessage = ref('')

  const addError = (message) => {
    if (message) {
      // console.log('addError', message)
      errors.value.push({ message, completed: false })
    }
  }

  const removeError = (message) => {
    if (message) {
      // console.log('removeError', message)
      const index = errors.value.findIndex((error) => error?.message === message)
      if (index !== -1) {
        errors.value.splice(index, 1)
      }
    }
  }

  const validateInput = ({ message, test }) => {
    const listed = errors.value.some((error) => error?.message === message)
    if (!test && !listed) {
      addError(message)
      isError.value = true
      errorMessage.value = message
    } else if (test && listed) {
      removeError(message)
      isError.value = false
      errorMessage.value = null
    }
  }
  return {
    isError,
    errorMessage,
    errors,
    addError,
    removeError,
    validateInput
  }
}
