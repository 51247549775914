// Import the functions you need from the SDKs you need
import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import {fbDoc, firebaseDb, storage} from './firebase_config.js'
import {getDownloadURL, ref, uploadBytesResumable} from 'firebase/storage'
import Compressor from 'compressorjs'

/** Developer created reusable functions for firebase actions */

/**
 *  Retrieves a single document from a Firebase collection.
 *  @function getFirebaseDocument
 *  @param {string} collectionName - The name of the Firebase collection to retrieve the document from.
 *  @param {string} docID - The ID of the Firebase document to retrieve.
 *  @returns {Object} The data of the retrieved Firebase document, or logs an error message if the document does not
 *   exist.
 */
function getFirebaseDocument (collectionName, docID) {
  const docRef = doc(firebaseDb, collectionName, docID)

  return getDoc(docRef).then((docSnap) => {
    if (docSnap.exists()) {
      return docSnap.data()
    } else {
      return null
    }
  }).catch((error) => {
    console.log('collection ' + collectionName + ' Error getting document:' + error)
    throw error
  })
}

/**
 * Get document or subcollection document from Firebase Firestore.
 *
 * @param {string} collection - The name of the Firestore collection.
 * @param {string} docId - The document ID.
 * @param {string} subCollection - The subcollection name. OPTIONAL.
 * @param {string} subDocId - The subcollection document ID. Required if subCollection is given.
 *
 * @return {Promise<Object>} The document data or an error message.
 */
async function getFirebaseData(
    collection, docId, subCollection = '', subDocId = '') {
  try {
    let docRef
    if (subCollection && subDocId) {
      // Get the subcollection document
      docRef = doc(firebaseDb, collection, docId, subCollection, subDocId)
    } else if (!subCollection && !subDocId) {
      // Get the collection document
      docRef = doc(firebaseDb, collection, docId)
    } else {
      throw new Error(
          'Inconsistent arguments: Either both subCollection and subDocId should be provided or neither.')
    }

    const docSnap = await getDoc(docRef)

    if (!docSnap.exists()) {
      throw new Error(
          `No document found with ID: ${docId} in collection: ${collection}`)
    }

    return {
      error: false,
      data: docSnap.data()
    }
  } catch (error) {
    return {
      error: true,
      message: error.message
    }
  }
}

/**
 * Get live document or subcollection document from Firebase Firestore.
 *
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {string} docId - The document ID.
 * @param {Function} callback - The callback function to handle the document data.
 * @param {string} subCollectionName - The subcollection name. OPTIONAL.
 * @param {string} subCollectionId - The subcollection Id. OPTIONAL.
 * @return {function} Firebase unsubscribe function.
 */

function getLiveFirebaseData(
    collectionName, docId, callback, subCollectionName, subCollectionId) {
  let ref;

  if (subCollectionName && subCollectionId) {
    // Get the subcollection document
    ref = doc(firebaseDb, collectionName, docId, subCollectionName, subCollectionId);
  } else if (subCollectionName && !subCollectionId) {
    // Get the subcollection document
    ref = collection(doc(firebaseDb, collectionName, docId), subCollectionName);
  } else {
    // Get the collection document
    ref = doc(firebaseDb, collectionName, docId);
  }

  return onSnapshot(
      ref,
      {includeMetadataChanges: true},
      (snapshot) => {
        if (!snapshot.exists()) {
          throw new Error('No such document!');
        } else if (snapshot.empty) {
          throw new Error(`${collectionName} ${docId} ${subCollectionName}' No such document!`);
        } else {
          const data = subCollectionName && !subCollectionId
              ? snapshot.docs.map((doc) => doc.data())
              : snapshot.data();
          callback(data);
        }
      },
      (error) => {
        throw new Error('Error getting document: ' + error);
      }
  );
}


function getFirebaseSubCollectionDocument (collectionName, docID, subcollectionName, subDocID) {
  const subDocRef = doc(firebaseDb, collectionName, docID, subcollectionName, subDocID)

  return getDoc(subDocRef).then((docSnap) => {
    if (docSnap.exists()) {
      return docSnap.data()
    } else {
      return null
    }
  }).catch((error) => {
    console.log('collection ' + collectionName + ' Error getting document:' + error)
    throw error
  })
}

/**
 * Retrieves a single document from a Firebase collection.
 *
 * @function getFirebaseDocument
 * @param {string} collectionName - The name of the Firebase collection to retrieve the document from.
 * @param {string} docID - The ID of the Firebase document to retrieve.
 * @param {function} callback - A callback function that is called every time the document is updated with the latest
 *   data.
 * @returns {function} A function that can be used to unsubscribe the listener and stop receiving updates from the
 * document.
 */
function getLiveFirebaseDocument (collectionName, docID, callback) {
  const docRef = doc(firebaseDb, collectionName, docID)

  return onSnapshot(
    docRef,
    { includeMetadataChanges: true },
    (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data()
        callback(data)
      } else {
        throw new Error('No such document!')
      }
    },
    (error) => {
      throw new Error('Error getting document: ' + error)
    }
  )
}

/**
 *  Retrieves all documents from a Firebase subcollection.
 *
 *  @function getFirebaseSubcollection
 *  @param {string} collectionName - The name of the Firebase collection that the subcollection belongs to.
 *  @param {string} docID - The ID of the Firebase document that the subcollection belongs to.
 *  @param {string} subcollectionName - The name of the Firebase subcollection to retrieve documents from.
 *  @returns {Promise<T>} An array of objects containing the data for each document in the subcollection, or logs an
 *   error message if the subcollection does not exist.
 */
function getFirebaseSubcollection (collectionName, docID, subcollectionName) {
  const subcollectionRef = collection(doc(firebaseDb, collectionName, docID), subcollectionName)

  return getDocs(subcollectionRef).then((
    snapshot) => {
    if (snapshot.empty) {
      console.log(`${collectionName} ${docID} ${subcollectionName}' No such document!`)
    }
    return snapshot.docs.map((doc) => doc.data())
  }).catch((error) => {
    console.log('collection ' + subcollectionName + ' Error getting subcollection:' + error)
    throw error
  })
}

/**
 * Retrieves all documents from a Firebase subcollection.
 *
 * @function getLiveFirebaseSubcollection
 * @param {string} collectionName - The name of the Firebase collection that the subcollection belongs to.
 * @param {string} docID - The ID of the Firebase document that the subcollection belongs to.
 * @param {string} subcollectionName - The name of the Firebase subcollection to retrieve documents from.
 * @param {function} callback - A callback function that is called every time the subcollection is updated with the
 * latest data.
 * @returns {function} A function that can be used to unsubscribe the listener and stop receiving updates from the
 * subcollection.
 */
function getLiveFirebaseSubcollection (collectionName, docID, subcollectionName, callback) {
  const subcollectionRef = collection(doc(firebaseDb, collectionName, docID), subcollectionName)

  return onSnapshot(subcollectionRef, (subcollectionSnapshot) => {
  /*  if (subcollectionSnapshot.empty) {
      console.log(`${collectionName} ${docID} ${subcollectionName}' No such document!`)
    }*/
    const data = subcollectionSnapshot.docs.map((doc) => doc.data())
    callback(data)
  })
}

/**
 *  Sets the value of a document in a Firebase collection.
 *  If document does not exist creates a document.
 *
 *  @function setFirebaseDocument
 *  @param {string} collectionName - The name of the collection.
 *  @param {string} docID - The name of the document.
 *  @param {object} data - The value to set in the document.
 *  @returns {object} - An object containing a success message and the updated value or an error message and a flag
 *   indicating an error occurred.
 */
function setFirebaseDocument (collectionName, docID, data) {
  const docRef = fbDoc(firebaseDb, collectionName, docID)

  setDoc(docRef, data, { merge: true }).then(() => {
    return { message: 'success', success: true, data }
  }).catch((error) => {
    // console.log('collection ' + collectionName + ' Error setting document:' + error)
    return { response: error.message, error: true }
  })
}

/**
 * Creates or updates an item in a Firestore subcollection document.
 *
 * @function setFirebaseSubcollectionDocument
 * @param {string} collectionName - The name of the parent collection.
 * @param {string} docID - The ID of the parent document.
 * @param {string} subcollectionName - The name of the subcollection.
 * @param {string} subcollectionDocID - The ID of the subcollection document.
 * @param {Object} data - The data to set or update in the subcollection document.
 * @returns {Promise<void>} A Promise that resolves when the data has been successfully set or updated in the
 *   subcollection document.
 */
function setFirebaseSubcollectionDocument (
  collectionName, docID, subcollectionName, subcollectionDocID, data) {
  const subcollectionDocRef = doc(firebaseDb, collectionName, docID, subcollectionName, subcollectionDocID)

  setDoc(subcollectionDocRef, data, { merge: true }).then(() => {
    return { message: 'success', success: true, data }
  }).catch((error) => {
    console.log('collection ' + subcollectionName + ' Error setting document:' + error)
    return { response: error.message, error: true }
  })
}

/**
 *  Updates a specific field in a document in a Firebase collection.
 *
 *  @function updateFirebaseDocumentField
 *  @param {string} collectionName - The name of the collection in which the document resides.
 *  @param {string} docID - The ID of the document to be updated.
 *  @param {string} field - The name of the field to be updated.
 *  @param {any} value - The new value to assign to the specified field.
 *  @returns {Promise<{message: string, success?: boolean, error?: boolean, value?: any}>} An object indicating the
 *   success or failure of the operation, along with any relevant data.
 *  @throws {Error} If an error occurs while updating the document.
 */
function updateFirebaseDocumentField (collectionName, docID, field, value) {
  const docRef = fbDoc(firebaseDb, collectionName, docID)

  updateDoc(docRef, { [field]: value }).then(() => {
    return { message: 'success', success: true, value }
  }).catch((error) => {
    console.log('Error updating document field: ' + field + ': ' + value + 'error: ' + error)
    return { message: error.message, error: true }
  })
}

/**
 * Creates or updates an item in a Firestore subcollection document.
 *
 *  @function setFirebaseSubcollectionDocument
 *  @param {string} collectionName - The name of the parent collection.
 *  @param {string} docID - The ID of the parent document.
 *  @param {string} subcollectionName - The name of the subcollection.
 *  @param {string} subcollectionDocID - The ID of the subcollection document.
 *  @param {string} field - The name of the field to be updated.
 *  @param {Object} data - The data to set to update in the subcollection document.
 *  @returns {Promise<{message: string, success?: boolean, error?: boolean, value?: any}>} An object indicating the
 *   success or failure of the operation, along with any relevant data.
 *  @throws {Error} If an error occurs while updating the document.
 */
function updateFirebaseSubcollectionDocumentField (
  collectionName, docID, subcollectionName, subcollectionDocID, field, data) {

  const subcollectionDocRef = doc(firebaseDb, collectionName, docID, subcollectionName, subcollectionDocID)

  updateDoc(subcollectionDocRef, { [field]: data }).then(() => {
    return { message: 'success', success: true, data }
  }).catch((error) => {
    console.log('collection ' + subcollectionName + ' Error setting document field:' + error)
    return { message: error.message, error: true }
  })
}

/**
 *  Updates a value in a Firebase array field
 *  @function updateFirebaseArray
 *  @param {string} collectionName - The name of the collection to update
 *  @param {string} doc - The ID of the document to update
 *  @param {string} field - The name of the array field to update
 *  @param {any} value - The value to add or remove from the array
 *  @param {boolean} [remove=false] - Whether to remove the value from the array or not (default: false)
 */
function updateFirebaseArray (collectionName, doc, field, value, remove = false) {

  const docRef = fbDoc(firebaseDb, collectionName, doc)
  const updateData = { [field]: remove ? arrayRemove(value) : arrayUnion(value) }

  updateDoc(docRef, updateData).then(() => {
    console.log('updateDoc complete')
    return { message: 'success', success: true, value }
  }).catch((error) => {
    console.log('Error updating array:', error)
    return { response: error.message, error: true }
  })
}

/**
 * Creates or updates an item in a Firestore subcollection document.
 *
 *  @function updateFirebaseSubcollectionArray
 *  @param {string} collectionName - The name of the parent collection.
 *  @param {string} docID - The ID of the parent document.
 *  @param {string} subcollectionName - The name of the subcollection.
 *  @param {string} subcollectionDocID - The ID of the subcollection document.
 *  @param {string} field - The name of the field to be updated.
 *  @param {Object} data - The data to set or update in the subcollection document.
 *  @param {boolean} [remove=false] - Whether to remove the value from the array or not (default: false)
 *  @returns {Promise<{message: string, success?: boolean, error?: boolean, value?: any}>} An object indicating the
 *   success or failure of the operation, along with any relevant data.
 *  @throws {Error} If an error occurs while updating the document.
 */
function updateFirebaseSubcollectionArray (
  collectionName, docID, subcollectionName, subcollectionDocID, field, data, remove = false) {

  const subcollectionDocRef = doc(firebaseDb, collectionName, docID, subcollectionName, subcollectionDocID)
  const updateData = { [field]: remove ? arrayRemove(data) : arrayUnion(data) }

  updateDoc(subcollectionDocRef, updateData).then(() => {
    return { message: 'success', success: true, data }
  }).catch((error) => {
    console.log('Error updating subcollection array:', error)
    return { response: error.message, error: true }
  })
}

/**
 * Deletes a document from a Firestore collection
 *
 *  @function deleteFirebaseDocument
 *  @param {string} collectionName - The name of the collection where the document is stored
 *  @param {string} docID - The ID of the document to delete
 *  @returns {Object} - A message object with a success or error message depending on the result of the operation
 *  @throws {Error} - If an error occurs while deleting the document
 */
function deleteFirebaseDocument (collectionName, docID) {
  const docRef = fbDoc(firebaseDb, collectionName, docID)

  deleteDoc(docRef).then(() => {
    return { message: 'success', success: true }
  }).catch((error) => {
    console.log('Error deleting document:', error)
    return { message: error.message, error: true }
  })
}

/**
 * Deletes a document from a Firestore subcollection
 *
 *  @function deleteFirebaseSubcollectionDocument
 *  @param {string} collectionName - The name of the collection where the document is stored
 *  @param {string} docID - The ID of the document to delete
 *  @param {string} subcollectionName - The name of the subcollection.
 *  @param {string} subcollectionDocID - The ID of the subcollection document.
 *  @returns {Object} - A message object with a success or error message depending on the result of the operation
 *  @throws {Error} - If an error occurs while deleting the document
 */
function deleteFirebaseSubcollectionDocument (
  collectionName, docID, subcollectionName, subcollectionDocID) {
  const subcollectionDocRef = doc(firebaseDb, collectionName, docID, subcollectionName, subcollectionDocID)

  deleteDoc(subcollectionDocRef).then(() => {
    return { message: 'success', success: true }
  }).catch((error) => {
    console.log('Error deleting subcollection document:', error)
    return { message: error.message, error: true }
  })
}

/**
 *  Deletes a specified field from a Firebase document in a given collection.
 *
 *  @param {string} collectionName - The name of the Firebase collection where the document exists.
 *  @param {string} docID - The ID of the Firebase document.
 *  @param {string} value - The name of the field to be deleted.
 *  @returns {Object} An object containing either a success or error message.
 */
function deleteFirebaseDocumentField (collectionName, docID, value) {
  const docRef = fbDoc(firebaseDb, collectionName, docID)

  updateDoc(docRef, { [value]: deleteField() }).then(() => {
    return { message: 'success', success: true }
  }).catch((error) => {
    console.log('Error deleting document field:', error)
    return { message: error.message, error: true }
  })
}

/**
 *  Deletes a specified field from a Firebase document in a given collection.
 *
 * @param {string} collectionName - The name of the collection where the document is stored
 *  @param {string} docID - The ID of the document to delete
 *  @param {string} subcollectionName - The name of the subcollection.
 *  @param {string} subcollectionDocID - The ID of the subcollection document.
 *  @param {string} fieldID - The name of the field to be deleted.
 *  @returns {Object} - A message object with a success or error message depending on the result of the operation
 *  @returns {Object} An object containing either a success or error message.
 */
function deleteFirebaseSubcollectionDocumentField (collectionName, docID, subcollectionName, subcollectionDocID,
  fieldID) {
  const subcollectionDocRef = doc(firebaseDb, collectionName, docID, subcollectionName, subcollectionDocID)

  updateDoc(subcollectionDocRef, { [fieldID]: deleteField() }).then(() => {
    return { message: 'success', success: true }
  }).catch((error) => {
    console.log('Error deleting subcollection document field:', error)
    return { message: error.message, error: true }
  })
}

async function uploadFileToStorage (file, storagePath, progressCallback) {
  const docRef = ref(storage, storagePath)
  let compressedFile = file
  try {
    if (compressedFile.type !== 'application/pdf') {
      compressedFile = await new Promise((resolve) => {
        new Compressor(file, {
          quality: 0.3,
          success (result) {
            resolve(result)
          },
          error (error) {
            console.log('Error compressing image:', error)
            throw error
          }
        })
      })
    }

    const uploadTask = uploadBytesResumable(docRef, compressedFile)

    uploadTask.on('state_changed', (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      if (progressCallback) {
        progressCallback(progress)
      }
    })

    const snapshot = await uploadTask
    return await getDownloadURL(snapshot.ref)
  } catch (error) {
    console.log('Error uploading image:', error)
    throw error
  }
}

// End User created functions

/**
 *  Returns a formatted error message for Firebase authentication errors.
 *
 *  @param {string} message - The error message from Firebase.
 *  @returns {string} - A formatted error message for the specific error.
 */
function firebaseMessage (message) {
  const authErrorMessages = {
    'wrong-password': 'Incorrect password, please try again.',
    'user-not-found': 'No user found with that email address.',
    'email-already-in-use': 'That email address is already in use.',
    'weak-password': 'The password is too weak, please choose a stronger one.',
    'invalid-email': 'The email address is not valid, please enter a valid email address.',
    'too-many-requests': 'Too many unsuccessful login attempts. Please try again later.',
    'popup-closed-by-user': 'Authorization popup closed by user. Please try again.'
  }

  const firestoreErrorMessages = {
    'not-found': 'The requested resource was not found.',
    'permission-denied': 'You do not have permission to perform this action.',
    'invalid-argument': 'The request was invalid or malformed.',
    'already-exists': 'The requested resource already exists.',
    aborted: 'The operation was aborted.',
    unavailable: 'The service is currently unavailable.',
    cancelled: 'The operation was cancelled.',
    'resource-exhausted': 'The resource has been exhausted.',
    'failed-precondition': 'The operation was rejected because the system is not in a state required for the operation.',
    'out-of-range': 'The operation was attempted past the valid range.',
    'deadline-exceeded': 'The operation timed out.',
    internal: 'An internal error occurred.',
    unauthenticated: 'You are not authenticated to perform this action.'
  }

  if (message.startsWith('Firebase: Error (auth/')) {
    return authErrorMessages[message.split('Firebase: Error (auth/')[1].slice(0, -2)] || message
  } else if (message.includes('FirebaseError: [code=')) {
    return firestoreErrorMessages[message.split('FirebaseError: [code=')[1].split(']')[0]] || message
  } else {
    return message
  }
}

function calculateDocumentSize (data) {
  const docNameSize = data?.__name__?.length || 0
  let fieldSize = 0

  Object.entries(data).forEach(([fieldName, fieldValue]) => {
    if (fieldName === '__name__') return // skip the document name field

    const fieldType = typeof fieldValue

    if (fieldType === 'string') {
      fieldSize += fieldValue.length + 1 // UTF-8 encoded bytes + 1
    } else if (fieldType === 'boolean' || fieldType === 'number') {
      fieldSize += 1 // boolean or number takes 1 byte
    } else if (fieldType === 'object' && fieldValue !== null) {
      fieldSize += JSON.stringify(fieldValue).length + 1 // object size
    } else {
      fieldSize += 1 // null takes 1 byte
    }
    fieldSize += fieldName.length // field name size
  })

  return docNameSize + fieldSize + 32 // additional 32 bytes
}

export {
  getFirebaseData,
  getLiveFirebaseData,
  // top level docs
  getFirebaseDocument,
  getLiveFirebaseDocument,
  setFirebaseDocument,
  updateFirebaseDocumentField,
  updateFirebaseArray,
  deleteFirebaseDocumentField,
  deleteFirebaseDocument,
  // subcollection docs
  getFirebaseSubcollection,
  getFirebaseSubCollectionDocument,
  getLiveFirebaseSubcollection,
  setFirebaseSubcollectionDocument,
  updateFirebaseSubcollectionDocumentField,
  updateFirebaseSubcollectionArray,
  deleteFirebaseSubcollectionDocument,
  deleteFirebaseSubcollectionDocumentField,
  //
  firebaseMessage,
  calculateDocumentSize,
  // storage
  uploadFileToStorage
}
