import {acceptHMRUpdate, defineStore} from 'pinia'
import {deleteObject, getDownloadURL, ref, uploadBytesResumable} from 'firebase/storage'
import {collection, doc, getDoc, onSnapshot, orderBy, query, writeBatch} from 'firebase/firestore'
import {calculateDocumentSize, firebaseDb, setFirebaseSubcollectionDocument, storage} from '@/apis'
import {useUserStore} from '@/stores/useUserStore.js'
import {usePlatform} from '@/composables/usePlatform.js'
import {imageData, useUid} from '@/utils/index.js'
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera'
import {cloneDeep} from 'lodash'
import {Directory, Filesystem} from '@capacitor/filesystem'
import {useFileUtils} from '@/composables/useFileUtils.js'
import {useStorage} from '@vueuse/core'
import {useCompanyStore} from '@/stores/useCompanyStore.js'

const IMAGE_DIR = 'tradebox'
let photoCounter = 1

export const usePhotoStore = defineStore('photo', {
  state: () => ({
    photos: new Map(),
    photoDocUid: '',
    photo_storage_used: 0,
    error: null,
    listeners: [],
    photo: {},
    alwaysCapture: useStorage('alwaysCapture', true),
    savePhotosToDevice: useStorage('savePhotosToDevice', true)
  }),

  getters: {
    sortedPhotos (state) {
      const photos = Array.from(state.photos.values()) || []
      return photos.sort((a, b) => b.created_at - a.created_at)
    },

    photoSpaceAvailable (state) {
      const companyStore = useCompanyStore()
      const plan = companyStore.company.plan.type
      const limits = {
        free: 0,
        pro: 1073741824, // 1GB
        pro_plus: 5368709120, // 5GB
        premium: 10737418240 // 10GB
      }
      return limits[plan] > state.photo_storage_used
    }

  },

  actions: {
    fetchPhoto (uid) {
      this.photo = this.photos.get(uid)
      return this.photo
    },

    fetchPhotos (docId) {
      const userStore = useUserStore()
      const companyId = docId || userStore.user.company_uid
      this.photoDocUid = companyId
      const subcollectionRef = collection(doc(firebaseDb, 'companies', companyId), 'photos')

      // Query the subcollection to retrieve documents in ascending order
      const q = query(subcollectionRef, orderBy('created_at', 'asc'))

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        // if query is empty create document
        if (querySnapshot.empty) {
          await setFirebaseSubcollectionDocument('companies', docId, 'photos', this.photoDocUid,
            { created_at: Date.now() })
        }

        // assign data to photo
        let lastDocData = {}
        querySnapshot.forEach((doc) => {
          const docData = doc.data()
          for (const photo of Object.values(docData)) {
            if (photo.uid) {
              this.photos.set(photo.uid, photo)
              this.photo_storage_used += photo.size
            }
          }
          lastDocData = docData
        })

        const docSize = calculateDocumentSize(lastDocData)

        if (docSize >= 800000) { // 0.8 MB - 800000 bytes
          this.photoDocUid = useUid()
          await setFirebaseSubcollectionDocument('companies', docId, 'photos', this.photoDocUid,
            { created_at: Date.now() })
        }

      })

      this.listeners.push(unsubscribe)
    },

    async readPhotoDirectory () {
      try {
        return await Filesystem.readdir({
          path: `${IMAGE_DIR}`,
          directory: Directory.Data
        })
      } catch (e) {
        try {
          await Filesystem.mkdir({
            path: `${IMAGE_DIR}`,
            directory: Directory.Data
          })
        } catch (error) {
          console.error('readPhotoDirectory - mkdir', error)
        }

      }
    },

    async takePhoto (user, company, docID, type) {
      // define source
      const source = type === 'capture' ? CameraSource.Camera : CameraSource.Photos
      const resultType = usePlatform.isCapacitor ? CameraResultType.Base64 : CameraResultType.Uri

      // capture image
      const photo = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        source,
        resultType,
        saveToGallery: this.savePhotosToDevice
      })

      try {
        const base64String = await useFileUtils().readAsBase64(photo)
        const base64WithoutPrefix = base64String.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')

        // generate data object
        const data = await this.generatePhotoData(user, company)
        data.local_path = `${IMAGE_DIR}/${docID}_${data.uid}`

        // init photo directory
        await this.readPhotoDirectory()

        // save file to user device
        await Filesystem.writeFile({
          path: data.local_path,
          data: base64WithoutPrefix,
          directory: Directory.Data,
          recursive: true
        })

        const { size, type } = await Filesystem.stat({
          path: data.local_path,
          directory: Directory.Data
        })

        /*if (image.exif.GPS) {
          const { latitude, longitude } = useFileUtils().getGPSDecimalCoordinates(image.exif.GPS)
          data.address.lat = latitude
          data.address.long = longitude
        }*/

        // convert base64 to url
        if (usePlatform.isCapacitor) {
          const blob = await useFileUtils().convertBase64ToBlob(base64String)
          data.url = URL.createObjectURL(blob)
        } else {
          data.url = photo.webPath
        }

        data.size = size
        data.type = type

        return data
      } catch (e) {
        throw new Error('takePhoto - error', e)
      }
    },

    // generate photo data
    async generatePhotoData (user, company) {
      const data = cloneDeep(imageData)

      data.uid = useUid()
      data.description = `photo_${photoCounter}`
      photoCounter++
      data.user_uid = user.uid
      data.company_uid = company.uid
      data.uploaded_by = user.name || user.email
      data.created_at = Date.now()
      data.storage_path = `${user.company_uid}/photos/${data.uid}`

      const activity = {
        uid: useUid(),
        type: 'uploaded',
        user: {
          uid: user.uid,
          name: user.name || user.email
        },
        created_at: Date.now()
      }
      data.activity.push(activity)

      return data
    },

    // sync estimate photos
    async syncDocumentPhotos (user, company, collection) {

      try {
        const files = await this.readPhotoDirectory()
        if (files?.files && files?.files?.length) {
          const documents = {}
          const filesToDelete = []

          for (const file of files.files) {
            const [doc_uid, photo_uid] = file.name.split('_')
            const image = await Filesystem.readFile({
              path: `${IMAGE_DIR}/${file.name}`,
              directory: Directory.Data
            })
            const blob = await useFileUtils().convertBase64ToBlob(image.data)
            const { url } = await this.uploadImage(user, company, photo_uid, blob)

            if (!documents[doc_uid]) {
              documents[doc_uid] = []
            }
            documents[doc_uid].push({ url, uid: photo_uid })
            filesToDelete.push(`${IMAGE_DIR}/${file.name}`)
          }

          await this.updateDocumentUrlsAndAddToPhotos(user, company, documents, collection)
          await this.deleteUploadedPhotoFiles(filesToDelete)

        }
      } catch (error) {
        throw new Error('Error Syncing Document Photos' + error)
      }
    },

    async uploadImage (user, company, photo_uid, blob) {
      const metadata = { customMetadata: { owner: user.uid, company: company.uid } }
      const storageRef = ref(storage, `${company.uid}/photos/${photo_uid}`)

      try {
        const uploadTask = uploadBytesResumable(storageRef, blob, metadata)

        return new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            () => {
              // You can handle progress updates here if needed
            },
            (error) => {
              console.error('Error uploading photo', error)
              reject(error)
            },
            async () => {
              try {
                const url = await getDownloadURL(uploadTask.snapshot.ref)
                resolve({ url })
              } catch (error) {
                console.error('Error getting download URL', error)
                reject(error)
              }
            }
          )
        })
      } catch (error) {
        throw new Error('Error uploading photo' + error)
      }
    },

    async updateDocumentUrlsAndAddToPhotos (user, company, documents, collection) {
      try {
        const batch = writeBatch(firebaseDb)
        const photoDocRef = doc(firebaseDb, 'companies', company.uid, 'photos', this.photoDocUid)

        // Loop through each item in the estimate/invoice object
        for (const doc_uid in documents) {
          const photos = documents[doc_uid]

          const docRef = doc(firebaseDb, 'companies', company.uid, collection, doc_uid)
          const docSnap = await getDoc(docRef)

          // if document exists update
          if (docSnap.exists()) {
            const data = docSnap.data()
            const clientUID = data.client?.uid
            const docPhotos = data.photos

            // Update the URL in the photos array of the estimate document
            for (const photo of photos) {
              const docPhoto = data.photos[photo.uid]

              if (docPhoto) {
                docPhoto.url = photo.url
                docPhoto.doc_uid = this.photoDocUid

                if (clientUID) {
                  docPhoto.client_uid = clientUID
                }

              } else {
                console.error('Document photo does not exist for uid:', photo.uid)
              }
            }

            const updatedPhotoMap = Object.fromEntries(
              Object.entries(docPhotos).map(
                ([key, value]) => [key, { uid: value.uid, url: value.url }]
              )
            )

            batch.update(docRef, { photos: updatedPhotoMap })

            // add Photo to photo doc
            for (const photo of Object.values(docPhotos)) {
              batch.set(photoDocRef, { [photo.uid]: photo }, { merge: true })
            }
          } else {
            console.error('Document does not exist')
          }
        }

        await batch.commit()

      } catch (e) {
        throw new Error('Error updating doc URLs and photo document' + e)
      }
    },

    async deleteUploadedPhotoFiles (filesToDelete) {
      try {
        for (const file of filesToDelete) {
          await Filesystem.deleteFile({
            path: file,
            directory: Directory.Data
          })
        }
      } catch (e) {
        throw new Error('deleteLocalPhoto error', e)
      }
    },

    async deletePhoto (data) {
      const docRef = ref(storage, data.storage_path)
      deleteObject(docRef).then(() => {
      }).catch((e) => {
        throw new Error('deletePhoto error', e)
      })
    },

    async deleteLocalPhoto (data) {
      if (data.url.startsWith('blob')) {
        try {
          await Filesystem.deleteFile({
            path: data.local_path,
            directory: Directory.Data
          })
        } catch (e) {
          throw new Error('deleteLocalPhoto error', e)
        }
      }
    },

    async fetchLocalPhotos (docUid) {
      try {
        const files = await this.readPhotoDirectory()
        const images = {}

        for (const file of files.files) {
          const image = await Filesystem.readFile({
            path: `${IMAGE_DIR}/${file.name}`,
            directory: Directory.Data
          })

          const blob = await useFileUtils().convertBase64ToBlob(image.data)
          const url = URL.createObjectURL(blob)

          const [doc_uid, photo_uid] = file.name.split('_')

          if (doc_uid === docUid) {
            images[photo_uid] = { uid: photo_uid, url }
          }
        }

        return images
      } catch (e) {
        throw new Error('fetchLocalPhotos', e)
      }
    },

    async fetchLocalPhotoUrl (local_path) {
      try {

        const image = await Filesystem.readFile({
          path: local_path,
          directory: Directory.Data
        })

        const blob = await useFileUtils().convertBase64ToBlob(image.data)
        return URL.createObjectURL(blob)

      } catch (e) {
        throw new Error('fetchLocalPhotos', e)
      }
    },

    unsubscribe () {
      this.listeners.forEach(fn => fn())
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePhotoStore, import.meta.hot))
}
