import { usePlatform } from '@/composables/usePlatform.js'

export function useFileUtils () {

  async function readAsBase64 (photo) {
    try {
      if (usePlatform.isCapacitor) {
        return photo.base64String
      } else {
        // Fetch the photo, read as a blob, then convert to base64 format
        const response = await fetch(photo.webPath)
        const blob = await response.blob()
        return await convertBlobToBase64(blob)
      }
    } catch (e) {
      throw new Error('readAsBase64 error', e)
    }
  }

  function convertBlobToBase64 (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = reject
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.readAsDataURL(file)
    })
  }

  function convertBase64ToBlob (base64Data) {
    if (!base64Data) {
      throw new Error('Invalid base64Data')
    }
    const binaryString = window.atob(base64Data)
    const length = binaryString.length
    const uint8Array = new Uint8Array(length)
    for (let i = 0; i < length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i)
    }
    return new Blob([uint8Array], { type: 'image/jpeg' })
  }

  function getGPSDecimalCoordinates (GPS) {
    if (!GPS) {
      throw new Error('GPS data not found in Exif')
    }

    const latitude = GPS?.Latitude
    const longitude = GPS?.Longitude

    const latDegrees = latitude[0]
    const latMinutes = latitude[1]
    const latSeconds = latitude[2]
    const latDirection = GPS?.LatitudeRef

    const longDegrees = longitude[0]
    const longMinutes = longitude[1]
    const longSeconds = longitude[2]
    const longDirection = GPS?.LongitudeRef

    const latitudeDecimal = convertDMSToDD(
      latDegrees,
      latMinutes,
      latSeconds,
      latDirection
    )
    const longitudeDecimal = convertDMSToDD(
      longDegrees,
      longMinutes,
      longSeconds,
      longDirection
    )

    return { latitude: latitudeDecimal, longitude: longitudeDecimal }
  }

  function convertDMSToDD (degrees, minutes, seconds, direction) {
    let dd = degrees + (minutes / 60) + (seconds / 3600)
    if (direction === 'S' || direction === 'W') {
      dd = -dd
    }
    return dd
  }

  return {
    readAsBase64,
    convertBlobToBase64,
    convertBase64ToBlob,
    getGPSDecimalCoordinates
  }

}
