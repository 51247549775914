import { ref } from 'vue'

/**
 * Formats a phone number input to include parentheses and a hyphen when appropriate
 * @param {string} phone - The phone number to format
 * @returns {object} - An object containing a ref for the formatted phone number and a function to format the input
 */
export function usePhone (phone) {
  const phoneRef = ref(phone)

  /**
   * Formats the phone number input when a user types in it
   * @param {KeyboardEvent} event - The keyboard event object
   * @param {unknown} event - The keyboard event object
   */
  function formatPhone (event) {
    const keyCode = event.keyCode

    // Only allow numbers and backspace key
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 8) {
      event.preventDefault()
    }

    // Limit input to 14 characters
    if (event.target.value.length >= 14) {
      event.preventDefault()
    }

    if (event.inputType === 'deleteContentBackward') {
      phoneRef.value = event.target.value
      return
    }

    let value = event.target.value

    // Remove non-numeric characters
    value = value.replace(/[^0-9]/g, '')

    // Add parentheses and hyphen when appropriate
    const areaCode = value.slice(0, 3)
    const prefix = value.slice(3, 6)
    const lineNumber = value.slice(6)

    if (value.length < 4) {
      phoneRef.value = areaCode
    } else if (value.length < 7) {
      phoneRef.value = `(${areaCode}) ${prefix}`
    } else {
      phoneRef.value = `(${areaCode}) ${prefix}-${lineNumber}`
    }
  }

  return { phoneRef, formatPhone }
}
