
const address = {
  street: '',
  unit: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  formatted: '',
  lat: null,
  long: null
}

const client = {
  uid: '',
  doc_uid: '',
  user_uid: '',
  company_uid: '',
  created_at: 0,
  name: '',
  name_alt: '',
  email: '',
  phone: '',
  phone_alt: '',
  address,
  notes: '',
  stripe: {}
}

const emailTemplate = {
  uid: '',
  title: '',
  subject: '',
  body: ''
}

const jobPost = {
  uid: '',
  active: true,
  created_at: null,
  position: {
    title: '',
    description: '',
    employment_type: 'full-time',
    unit_amount: '',
    unit_amount_per: 'hour'
  },
  contact: {
    name: '',
    email: '',
    phone: ''
  },
  tags: null
}

const link = {
  uid: '',
  name: '',
  description: '',
  href: '',
  external: true,
  type: 'social'
}

const tax = {
  uid: '',
  name: '',
  percent: null,
  stripe: {
    id: ''
  }
}

const notification = {
  uid: '',
  user_uid: '',
  company_uid: '',
  title: '',
  body: '',
  href: '',
  badge: 1,
  created_at: 0,
  devices: []
}

const event = {
  summary: '',
  description: '',
  location: address,
  start: {
    dateTime: '',
    date: '',
    timeZone: ''
  },
  end: {
    dateTime: '',
    date: '',
    timeZone: ''
  },
  attendees: [],
  reminders: {
    useDefault: true,
    overrides: []
  }
}

const estimate = {
  uid: '',
  company_uid: '',
  user_uid: '',
  days_valid: 30,
  reference_number: 100,
  created_at: 0,
  client: client,
  items: [],
  attachments: [],
  photos: {},
  activity: [],
  comments: [],
  notes: '',
  status: 'draft',
  deposit: 0,
  discount: 0,
  total: 0,
  signature: null,
  stripe: {
    id: '',
    hosted_estimate_url: '',
    estimate_pdf: ''
  }
}

const invoice = {
  uid: '',
  company_uid: '',
  user_uid: '',
  reference_number: 100,
  created_at: 0,
  client: client,
  items: [],
  attachments: [],
  photos: {},
  activity: [],
  comments: [],
  notes: '',
  status: 'draft',
  deposit: 0,
  discount: 0,
  total: 0,
  days_until_due: 7,
  stripe: {
    id: '',
    hosted_invoice_url: '',
    invoice_pdf: ''
  }
}

const lineItem = {
  uid: '',
  type: 'labor', // product or labor
  product: {
    id: '',
    name: '',
    description: ''
  },
  price: {
    id: '',
    unit_amount: null,
    unit_amount_wholesale: null
  },
  quantity: 0,
  calculator: false,
  tax_rates: [],
  tags: []
}

const fileData = {
  uid: '',
  name: '',
  description: '',
  file: '',
  size: 0,
  type: '',
  url: '',
  created_at: 0,
  storage_path: ''
}

const imageData = {
  uid: '',
  doc_uid: '',
  user_uid: '',
  company_uid: '',
  client_uid: '',
  description: '',
  url: '',
  size: 0,
  local_path: '',
  storage_path: '',
  isFavorite: false,
  address,
  shared_with: [],
  activity: [],
  tags: []
}

const message = {
  uid: '',
  message: '',
  support: false,
  user: { uid: '', name: '' },
  read: false,
  created_at: 0
}

const supportTicket = {
  uid: '',
  user_uid: '',
  user_plan: '',
  number: 0,
  created_at: 0,
  resolved_at: 0,
  resolved: false,
  name: '',
  email: '',
  phone: '',
  subject: '',
  note: '',
  messages: [message],
  tags: [],
  admin: {
    uid: '',
    name: 'Adam Padolsky',
    email: 'support@paintbox.app'
  }
}

const user = {
  uid: '',
  company_uid: '',
  name: '',
  email: '',
  phone: '',
  avatar: fileData,
  created_at: 0,
  last_sign_in: 0,
  email_notifications: false,
  auto_cc_email: false,
  device: '',
  platform: {
    capacitor: false,
    platform: ''
  },
  google: {
    access_token: '',
    refresh_token: ''
  }
}

const listItem = {
  uid: '',
  title: '',
  items: []
}


const company = {
  uid: '',
  admin: [],
  created_at: 0,
  business: {
    structure: '',
    trade: '',
    license_number: '',
    currency: {
      code: 'usd',
      label: 'US Dollar'
    }
  },
  contact: {
    address,
    name: '',
    phone: '',
    email: '',
    website: ''
  },
  geoHash: '',
  logo: fileData,
  connected: {
    contractors: [],
    sent_requests: [],
    received_requests: []
  },
  plan: {
    type: 'free',
    provider: '',
    will_lapse: false,
    has_downgraded: false,
    period_end: 0,
    stripe: {
      customer_uid: '',
      subscription_uid: ''
    },
    stripe_connect: {
      account_uid: '',
      details_submitted: false,
      charges_enabled: false
    }
  },
  review_statistics: {
    review_total: 0,
    total_star_count: 0
  },
  review_links: {
    tradebox: '',
    enable_tradebox_reviews: true,
    yelp: '',
    google: ''
  },
  reference: {
    estimate: {
      number: 100,
      period_count: 0,
      days_valid: 30
    },
    invoice: {
      number: 100,
      period_count: 0,
      days_until_due: 7
    },
    client: {
      number: 100,
      period_count: 0
    },
    ai: {
      period_tokens: 10000,
      available_tokens: 0
    }
  },
  market: {
    status: '',
    job_posts: [],
    job_wanted: []
  }
}

export {
  address,
  estimate,
  invoice,
  client,
  emailTemplate,
  imageData,
  event,
  fileData,
  jobPost,
  lineItem,
  link,
  tax,
  user,
  company,
  notification,
  message,
  supportTicket,
  listItem
}
