import {acceptHMRUpdate, defineStore} from 'pinia'
import {cloneDeep} from 'lodash'
import {Capacitor} from '@capacitor/core'
import {Badge} from '@capawesome/capacitor-badge'
import {usePlatform} from '@/composables/usePlatform'
import {SplashScreen} from '@capacitor/splash-screen'
import {company as companyRef, user as userRef} from '@/utils'
import {FirebaseAuthentication} from '@capacitor-firebase/authentication'
import {useUserStore} from '@/stores/useUserStore.js'
import {useLeadStore} from '@/stores/useLeadStore.js'
import {usePhotoStore} from '@/stores/usePhotoStore.js'
import {useGlobalStore} from '@/stores/useGlobalStore.js'
import {useClientStore} from '@/stores/useClientStore.js'
import {useDeviceStore} from '@/stores/useDeviceStore.js'
import {useCompanyStore} from '@/stores/useCompanyStore.js'
import {useInvoiceStore} from '@/stores/useInvoiceStore.js'
import {useCalendarStore} from '@/stores/useCalendarStore.js'
import {useResourceStore} from '@/stores/useResourceStore.js'
import {useEstimateStore} from '@/stores/useEstimateStore.js'
import {useNotificationStore} from '@/stores/useNotificationStore.js'
import {
  firebaseAuth,
  firebaseMessage,
  functions,
  httpsCallable,
  updateFirebaseDocumentField
} from '@/apis'
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth'

const {isCapacitor} = usePlatform

export const useAuthStore = defineStore('auth', {
  state: () => ({
    listeners: []
  }),

  getters: {},

  actions: {
    /**
     * Asynchronously creates a new user and company in Firebase using the given email, password, and user and company
     * data.
     * @param {string} email - The email address to use for the new user and company.
     * @param {string} password - The password to use for the new user account.
     * @param {string} service - The firebase auth service to be used to sign in.
     * @returns {Promise<undefined>} A promise that resolves with undefined when the operation is complete.
     */
    async createUserAndCompany(email, password, service) {
      const globalStore = useGlobalStore()
      const userStore = useUserStore()

      let result
      try {
        if (service === 'email') {
          result = await createUserWithEmailAndPassword(firebaseAuth, email, password)
        } else if (service === 'google') {
          result = await this.signInWithGoogle()
        } else if (service === 'apple') {
          result = await this.signInWithApple()
        }

        const uid = result.user.uid
        const displayName = result.user?.displayName || result.user?.providerData?.displayName || ''
        const userEmail = email.toLowerCase() || result.user?.email.toLowerCase()
        const photoURL = result.user?.photoURL || result.user?.providerData?.photoURL || ''
        const phoneNumber = result.user?.phoneNumber || result.user?.providerData?.phoneNumber || ''

        try {
          await userStore.fetchUserProfile(uid)
          if (userStore.user.uid && userStore.user.uid === uid) {
            this.router.push('/')
            return
          }
        } catch (e) {
        }

        // Assign data to user
        const user = cloneDeep(userRef)
        user.uid = uid
        user.company_uid = uid
        user.email = userEmail
        user.name = displayName
        user.avatar.url = photoURL
        user.phone = phoneNumber
        user.platform = usePlatform

        // Assign data to company
        const company = cloneDeep(companyRef)
        company.contact.email = userEmail
        company.contact.phone = phoneNumber
        company.contact.name = displayName
        company.logo.url = photoURL
        company.uid = uid
        company.admin.push(uid)

        const callable = httpsCallable(functions, 'firebaseCreateUserAndCompany')
        await callable({
          user,
          company
        })
        globalStore.openNotify('success', 'Welcome to Paintbox!', 'Let\'s get to work.')
      } catch (error) {
        globalStore.openNotify('error', 'Error Creating Account', firebaseMessage(error.message))
      }
    },

    /**
     * Asynchronously signs in a user using the given email and password.
     * @async
     * @function
     * @param {string} email - The email address to use for the sign-in.
     * @param {string} password - The password to use for the sign-in.
     * @param {string} service - The firebase auth provider to sign in with.
     * @param {boolean} reauthenticate - The firebase auth provider to sign in with.     * @returns {Promise<{uid: *}>}
     *   A promise that resolves with undefined when the sign-in is complete.
     */
    async signInUser(email, password, service, reauthenticate) {
      const globalStore = useGlobalStore()
      try {
        switch (service) {
          case 'email':
            if (reauthenticate) await this.reauthenticateUser(password)
            await signInWithEmailAndPassword(firebaseAuth, email, password)
            break
          case 'google':
            await this.signInWithGoogle(reauthenticate)
            break
          case 'apple':
            await this.signInWithApple(reauthenticate)
            break
          default:
            throw new Error('Unsupported sign-in service')
        }

        return {success: true}
      } catch (error) {
        globalStore.openNotify('error', 'Error Signing in', firebaseMessage(error.message))
      }
    },

    async reauthenticateUser(password) {
      const currentUser = firebaseAuth.currentUser
      const credential = EmailAuthProvider.credential(currentUser.email, password)
      await reauthenticateWithCredential(currentUser, credential)
      return currentUser
    },

    // Capacitor
    async signInWithGoogle(reauthenticate) {
      const result = await FirebaseAuthentication.signInWithGoogle()
      const credential = GoogleAuthProvider.credential(result.credential.idToken)
      let signInCredential
      if (reauthenticate) {
        const currentUser = firebaseAuth.currentUser
        await currentUser.reauthenticateWithCredential(credential)
      } else {
        signInCredential = await signInWithCredential(firebaseAuth, credential)
      }
      return signInCredential
    },


    // Capacitor
    async signInWithApple(reauthenticate) {
      const result = await FirebaseAuthentication.signInWithApple({
        skipNativeAuth: true
      })
      const provider = new OAuthProvider('apple.com')
      const credential = provider.credential({
        idToken: result.credential?.idToken,
        rawNonce: result.credential?.nonce
      })

      let signInCredential
      if (reauthenticate) {
        const currentUser = firebaseAuth.currentUser
        await currentUser.reauthenticateWithCredential(credential)
      } else {
        signInCredential = await signInWithCredential(firebaseAuth, credential)
      }

      return signInCredential
    },

    /**
     * Asynchronously signs out the current user.
     */
    async signOutUser() {
      try {
        const stores = [
          useUserStore(), useCompanyStore(), useEstimateStore(), useResourceStore(), useNotificationStore(), useClientStore(), useCalendarStore(), usePhotoStore(), useLeadStore()
        ]

        if (Capacitor.isNativePlatform) {
          await FirebaseAuthentication.signOut()
        }
        await signOut(firebaseAuth)

        // Unsubscribe listeners and reset states in all stores
        await Promise.all(stores.map(async (store) => {
          if (store.unsubscribe) {
            await store.unsubscribe()
          }
          await store.$reset()
        }))

        this.router.push('/auth')
        // Route user
        location.reload()
      } catch (error) {
        const globalStore = useGlobalStore()
        globalStore.openNotify('error', 'Error Signing out', firebaseMessage(error.message))
      }
    },

    /**
     * Asynchronously sends a password reset email to the given email address.
     * @async
     * @function
     * @param {string} email - The email address to send the password reset email to.
     * @returns {Promise<undefined>} A promise that resolves with undefined when the password reset email is sent.
     */
    async resetPassword(email) {
      const globalStore = useGlobalStore()
      try {
        await sendPasswordResetEmail(firebaseAuth, email)
        globalStore.openNotify('success', 'Success', 'Please check your email for password reset instructions.')
      } catch (error) {
        globalStore.openNotify('error', 'Error Resetting Password', 'There was an issue. Please check your email and try again.')
      }
    },

    async verifyEmail() {
      const globalStore = useGlobalStore()
      try {
        await sendEmailVerification(firebaseAuth.currentUser)
        globalStore.openNotify('success', 'Success', 'Verify your account via email.')
      } catch (e) {
        globalStore.openNotify('error', 'Error', 'Try again later.')
      }
    },

    /**
     * Sets up a listener to the Firebase authentication state and sets the 🍍Pinia state accordingly, as well as
     * fetching
     * and updating relevant data in the 🍍Pinia store.
     * @function
     * @returns {undefined} Returns undefined.
     */
    async handleAuthStateChange() {
      onAuthStateChanged(firebaseAuth, async (user) => {
        const userStore = useUserStore()
        const companyStore = useCompanyStore()
        const estimateStore = useEstimateStore()
        const resourceStore = useResourceStore()
        const useNotifications = useNotificationStore()
        const clientStore = useClientStore()
        const useCalendar = useCalendarStore()
        const usePhotos = usePhotoStore()
        const deviceStore = useDeviceStore()
        const globalStore = useGlobalStore()
        const invoiceStore = useInvoiceStore()

        if (user) {
          // console.log('🔥Auth Change')
          await deviceStore.enableFirestoreNetwork()
          const userUid = user.uid

          // Fetch user data
          try {
            await userStore.fetchUserProfile(userUid)

            if (userStore.user?.uid) {
              if (['/auth', '/sign-up', '/sign-in'].includes(this.router.currentRoute.value.fullPath)) {
                await companyStore.fetchCompanyProfile(userStore.user?.company_uid)
                companyStore.initializeCompanyData()
                this.router.push('/')
              }
            } else {
              globalStore.openNotify('error', 'Error signing in', 'No account found associated with this email address. Please try signing in with another account or create a new TradeBox account.')
              this.router.push('/auth')
            }
          } catch (e) {
            this.router.push('/auth')
            throw new Error('User profile not found' + e)
          }

          const company_uid = userStore.user.company_uid
          // Set company
          if (!companyStore.company.uid) {
            await companyStore.fetchCompanyProfile(company_uid)
            companyStore.initializeCompanyData()
          }

          // Fetch estimates
          if (!Object.keys(estimateStore?.estimates).length) {
            await estimateStore.fetchEstimates(company_uid)
          }
          // Fetch invoices
          if (!Object.keys(invoiceStore?.invoices).length) {
            await invoiceStore.fetchInvoices(company_uid)
          }

          // Fetch notifications
          const notifications = await useNotifications.fetchNotifications(userUid)

          // Fetch documents - Attachments, email templates, items, etc
          await resourceStore.fetchCompanyDocuments(company_uid)
          await resourceStore.fetchUserItems(company_uid)

          // Fetch photos
          if (Object.keys(usePhotos.photos).length === 0) {
            await usePhotos.fetchPhotos(company_uid)
          }
          // Fetch clients
          if (Object.keys(clientStore.clients).length === 0) {
            await clientStore.fetchClients(company_uid)
          }

          // set google calendar token
          if (userStore.user.google.access_token) {
            useCalendar.google.access_token = userStore.user.google.access_token
          }
          if (userStore.user.google.refresh_token) {
            useCalendar.google.refresh_token = userStore.user.google.refresh_token
          }

          // handle capacitor device
          if (isCapacitor) {
            await SplashScreen.hide()
            await useNotifications.registerNotifications()
            const {isSupported} = await Badge.isSupported()
            const count = notifications?.length
            if (isSupported) await Badge.set({count})
          }

          // Updated user signed in time
          await updateFirebaseDocumentField('users', userUid, 'last_sign_in', Date.now())
        } else {
          // User is signed out
        }
      })
    },

    async createTokensFromCode(code, uid) {
      try {
        await fetch('https://us-central1-tradebox-2678f.cloudfunctions.net/googleCreateAndSaveTokens', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            code,
            uid
          })
        })
      } catch (e) {
        throw new Error('error' + e)
      }
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}

