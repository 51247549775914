import {cloneDeep, isEqual} from 'lodash'
import {Browser} from '@capacitor/browser'
import {company as companyRef} from '@/utils'
import {httpsCallable} from 'firebase/functions'
import {doc, onSnapshot} from 'firebase/firestore'
import {acceptHMRUpdate, defineStore} from 'pinia'
import {firebaseDb, functions, geofire, setFirebaseDocument} from '@/apis'
import {useUserStore} from '@/stores/useUserStore.js'
import {useGlobalStore} from '@/stores/useGlobalStore.js'

export const useCompanyStore = defineStore('company', {
  state: () => ({
    company: companyRef,
    clone: companyRef,
    offline_mode: JSON.parse(localStorage.getItem('offline_mode')),
    listeners: [],
    activeTemplate: null
  }),

  getters: {
    isPremium (state) {
      return ['pro', 'pro_plus', 'premium'].includes(state.company.plan.type)
    },

    userPlan (state) {
      return state.company.plan.type === 'pro_plus' ? 'pro plus' : state.company.plan.type
    },

    userPlanLong (state) {
      const plan = state.company.plan.type
      return `${plan === 'pro' ? 'Professional' : plan === 'pro_plus' ? 'Professional Plus' : plan === 'premium' ? 'Premium' : plan}`
    },

    companyProfileChanged (state) {
      return !isEqual(state.company, state.clone)
    },

    logo (state) {
      new URL(state.company?.logo?.url, import.meta.url)
    },

    currencyCode (state) {
      return state.company.business.currency.code
    },

    companyPercentComplete (state) {
      const companyFields = {
        name: state.company.contact.name,
        email: state.company.contact.email,
        phone: state.company.contact.phone,
        address: state.company.contact.address.formatted,
        website: state.company.contact.website,
        trade: state.company.business.trade,
        structure: state.company.business.structure,
        logo: state.company.logo.url
      }

      const totalFields = Object.keys(companyFields).length
      const filledFields = Object.values(companyFields).filter(field => !!field).length

      return Math.round((filledFields / totalFields) * 100)
    }
  },

  actions: {
    async fetchCompanyProfile (docID) {
      return new Promise((resolve, reject) => {
        const docRef = doc(firebaseDb, 'companies', docID)
        const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            this.company = docSnapshot.data()
            resolve(this.company)
          } else {
            reject(new Error('No such document!'))
          }
        }, (error) => {
          reject(new Error('Error getting document: ' + error))
        })

        this.listeners.push(unsubscribe)
      })
    },

    cloneCompany () {
      this.clone = cloneDeep(this.company)
    },

    async saveCompanyProfile () {
      const globalStore = useGlobalStore()
      const useUser = useUserStore()
      const docID = useUser.user.company_uid

      if (this.companyProfileChanged && this.clone.uid) {
        // If user address is different: set geoHash
        try {
          if (this.company.contact.address !== this.clone.contact.address) {
            if (this.clone.contact.address.lat && this.clone.contact.address.long) {
              const lat = this.clone.contact.address.lat
              const long = this.clone.contact.address.long
              this.clone.geoHash = geofire.geohashForLocation([lat, long])
            }
          }

          await setFirebaseDocument('companies', docID, this.clone)
          this.company = cloneDeep(this.clone)
          globalStore.openNotify(
            'success',
            'Success',
            'Your company profile has been updated.'
          )
        } catch (error) {
          console.error(error)
          return { error: true, message: 'error' }
        }
      }
    },

    async authorizeStripeConnect (company) {
      try {
        if (!company.plan.stripe_connect.account_uid) {
          const callable = httpsCallable(functions, 'stripeCreateConnectAccount')
          const { data } = await callable({ company })
          company.plan.stripe_connect.account_uid = data.account.id
        }
        await this.fetchConnectAccountUrl(company)
      } catch (e) {
        console.log('error ', e)
      }
    },

    async fetchConnectAccountUrl (company) {
      const callbackUrl = `https://tradeboxpro.app/stripe-connect?stripe_account_uid=${company.plan.stripe_connect.account_uid}`

      try {
        const callable = httpsCallable(functions, 'stripeConnectAccountUrl')
        const { data } = await callable({
          company,
          refresh_url: callbackUrl,
          return_url: callbackUrl
        })
        const url = data.accountLink.url
        await Browser.open({ url })
      } catch (e) {
        console.error('Error getting connect account URL:', e)
      }
    },

    async fetchStripeConnectAccount (stripe_account_uid) {
      try {
        if (stripe_account_uid) {
          const callable = httpsCallable(functions, 'stripeFetchConnectAccount')
          const { data } = await callable({ stripe_account_uid })
          return data.account
        }
      } catch (e) {
        console.log('error ', e)
      }
    },

    initializeCompanyData () {
      const globalStore = useGlobalStore()
      const userStore = useUserStore()
      const lastSignIn = userStore.user.last_sign_in
      const lastSignInMonth = new Date(lastSignIn).getMonth()
      const currentMonth = new Date(Date.now()).getMonth()
      const reference = {
        ai: { ...this.company.reference.ai, period_tokens: 10000 },
        client: { ...this.company.reference.client, period_count: 0 },
        invoice: { ...this.company.reference.invoice, period_count: 0 },
        estimate: { ...this.company.reference.estimate, period_count: 0 }
      }

      if (lastSignInMonth !== currentMonth && this.company.uid) {
        setFirebaseDocument(
          'companies',
          `${this.company.uid}`,
          { reference }
        )
      }

      if (this.companyPercentComplete < 85) {
        globalStore.openBanner(
          'warning',
          `Company profile is ${this.companyPercentComplete}% complete.`,
          {
            label: 'Update profile', action: () => {
              this.router.push('/settings/profile/company')
              globalStore.closeBanner()
            }
          }
        )
      }
    },

    // unsubscribe from listeners on auth state change
    unsubscribe () {
      this.listeners.forEach(fn => fn())
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompanyStore, import.meta.hot))
}
