/**
 *  Detects the platform information based on user agent.
 *  @param {string} userAgent - The user agent to be analyzed.
 *  @returns {Object} An object containing platform information.
 *  capacitor (boolean): Indicates if the app is running in Capacitor.
 *  platform (string): The platform of the device. Possible values are 'apple', 'android', or 'other'.
 *  @throws {Error} If the userAgent parameter is not a string.
 */
import { Capacitor } from '@capacitor/core'

function detectPlatform (userAgent) {
  if (typeof userAgent !== 'string') {
    throw new Error('Invalid userAgent parameter')
  }

  const isCapacitor = Capacitor.isNativePlatform()
  const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0
  const isApple = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) || /(iPhone|iPod|iPad)/i.test(navigator.userAgent)
  const isAndroid = /android/i.test(userAgent)
  const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') === 0 && navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 && navigator.userAgent.indexOf('FxiOS') === -1

  return {
    isCapacitor: isCapacitor,
    isTouchDevice,
    device: isApple ? 'apple' : isAndroid ? 'android' : 'other',
    isApple: isApple && (isSafari || window.Capacitor !== undefined),
    isAndroid
  }
}

export const usePlatform = detectPlatform(navigator.userAgent || navigator.vendor || window.opera)
