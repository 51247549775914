<script setup>
import {ref} from 'vue'
import {onClickOutside} from '@vueuse/core'
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: ''
  },
  options: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['close'])

const target = ref(null)
const focus = ref(null)

onClickOutside(target, () => closeDialog())

function closeDialog() {
  emit('close')
}

function handleClick(option) {
  option.action()
  closeDialog()
}
</script>

<template>
  <TransitionRoot
    :show="props.show"
    as="template"
  >
    <Dialog
      :initial-focus="focus"
      as="div"
      class="relative z-30"
      @close="closeDialog"
    >
      <!-- Background overlay  -->
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <!-- Main content -->
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              ref="target"
              class="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-950 p-4 py-6 sm:p-6 text-left shadow-xl border border-gray-900/10 dark:border-white/20 transition-all  w-full sm:max-w-sm "
            >
              <input
                ref="focus"
                class="hidden"
              >

              <!-- Heading -->
              <div class="text-center sm:mt-4 mb-10">
                <DialogTitle
                  v-if="props.title"
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                >
                  {{ props.title }}
                </DialogTitle>

                <div
                  v-if="text"
                  class="mt-2"
                >
                  <p class="text-sm text-gray-500">
                    {{ props.text }}
                  </p>
                </div>
              </div>

              <!-- actions -->
              <div
                v-if="props.options.length"
                class="mt-5 sm:mt-8 space-y-4"
              >
                <template
                  v-for="option in props.options"
                  :key="option.label"
                >
                  <base-button-secondary
                    v-if="!option.hide && option.secondary"
                    class="w-full capitalize"
                    @click="handleClick(option)"
                  >
                    {{ option.label }}
                  </base-button-secondary>

                  <base-button-primary
                    v-else-if="!option.hide"
                    class="w-full capitalize"
                    @click="handleClick(option)"
                  >
                    {{ option.label }}
                  </base-button-primary>
                </template>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
