<script setup>
import {computed, onMounted, useSlots, watch} from 'vue'
import {useValidate} from '@/composables/useValidate.js'
import {XMarkIcon} from '@heroicons/vue/24/outline'
import {useTextareaAutosize} from '@vueuse/core'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null
  },
  label: {
    type: [String, Number],
    default: null
  },
  required: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'text'
  },
  component: {
    type: String,
    default: 'input'
  },
  clearable: {
    type: Boolean,
    default: true
  },
  hint: {
    type: [String, Number],
    default: null
  },
  rules: {
    type: Function,
    default: () => {
    }
  },
  error: {
    type: Boolean,
    default: false
  },
  inputStyle: {
    type: String,
    default: ''
  },
  inputClass: {
    type: String,
    default: ''
  },
  borderless: {
    type: Boolean,
    default: false
  },
  altColor: {
    type: Boolean,
    default: false
  }

})
const emit = defineEmits(['update:modelValue'])

const {
  textarea,
  input,
  triggerResize
} = useTextareaAutosize()

const {
  isError,
  errorMessage,
  validateInput
} = useValidate()

const slots = useSlots()

const classes = computed(() => {
  return [
    props.error || isError?.value ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 dark:border-white/10 focus:border-blue-500 focus:ring-blue-500 ',
    slots.prefix && props.type === 'search' ? 'pl-12' : slots.prefix ? 'pl-8' : null,
    (slots.suffix || props.clearable) && 'pr-8',
    props.borderless ? 'border-0' : 'border shadow-sm',
    props.inputClass,
    'block w-full rounded-md bg-white dark:bg-[#11141d] sm:text-sm resize-none min-h-[32px] text-gray-900 dark:text-white placeholder:text-gray-400 px-2 scroll-bar--none focus:bg-white dark:focus:bg-gray-950'
  ]
})

onMounted(() => {
  input.value = props.modelValue
})

watch(
    () => props.modelValue,
    (value) => {
      updateInput(value)
    }
)

function updateInput(value) {
  if (value) {
    input.value = value
  }
  if (props.required) {
    isRequired(value)
  }
  if (!value) {
    clearInput()
  }
  setTimeout(() => {
    triggerResize()
  }, 150)
}

function clearInput() {
  if (input.value !== '') {
    setTimeout(async () => {
      if (typeof input.value === 'number') {
        input.value = 0
      } else {
        input.value = ''
      }
      emit('update:modelValue', input.value)
    }, 100)
  }
}

function isRequired(value) {
  setTimeout(() => {
    if (props.required) {
      validateInput({
        message: props.label ? `${props.label} is required` : 'Field required',
        test: !!value
      })
    }
  }, 200)
}
</script>

<template>
  <div class="w-full relative">
    <!-- screen reader label -->
    <label
      :for="props.label"
      class="sr-only"
    >{{ props.label }}</label>

    <!-- Visible label -->
    <label
      v-if="props.label"
      :for="props.label"
      class="block text-sm/6 font-medium text-gray-900 dark:text-white"
    >
      {{ props.label }}
    </label>

    <!-- input container -->
    <div :class="[ props.label ? 'mt-2': null, 'w-full relative']">
      <!-- Prefix -->
      <div
        v-if="$slots.prefix"
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400 dark:text-gray-600"
      >
        <slot name="prefix" />
      </div>

      <!-- Input -->
      <textarea
        ref="textarea"
        v-model="input"
        :class="classes"
        :name="props.label"
        :type="props.type"
        autocomplete="off"
        v-bind="$attrs"
        @input="emit('update:modelValue', input)"
      />

      <!-- Suffix -->
      <div
        v-if="$slots.suffix"
        class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 dark:text-gray-600"
      >
        <slot name="suffix" />
      </div>

      <!-- Clearable -->
      <button
        v-if="props.clearable && props.modelValue"
        class="pointer-events-auto absolute right-0 top-3 pr-3"
        type="button"
        @click="clearInput()"
      >
        <XMarkIcon
          aria-hidden="true"
          class="h-4 w-4 text-gray-400 hover:text-gray-600 dark:hover:text-gray-200"
        />
      </button>
    </div>

    <!-- Hint / Error -->
    <div class="flex justify-between absolute right-0 w-full">
      <!-- error message - hint -->
      <div>
        <p
          v-if="props.hint || errorMessage"
          id="input-hint"
          :class="[
            error || isError ? 'text-red-600' : 'text-gray-500',
            'pt-1 pl-1 text-xs bg-transparent mr-4',
          ]"
        >
          {{ isError ? errorMessage : props.hint }}
        </p>
      </div>

      <!-- required -->
      <p
        v-if="props.required"
        class="text-xs text-gray-500 pt-1 pr-1"
      >
        {{ props.required ? 'Required' : '' }}
      </p>
    </div>
  </div>
</template>

<style scoped>
textarea {
  overflow: hidden; /* Hide the scrollbar */
  resize: none; /* Prevent the user from resizing the textarea */
}
</style>
