import {DateTime} from 'luxon'

const userTimeZone = DateTime.local().zoneName

/**
 *  Formats a given date according to the specified format.
 *
 *  @param {number, string, object} date - The date to be formatted.
 *  @param {string} format - The format to be applied to the date.
 *  @returns {string} The formatted date.
 */

export const useFormatDate = (date, format = 'long') => {
  try {
    // check if date is a timestamp, date object or string and format accordingly
    let d
    if (typeof date === 'number') {
      d = date.toString().length > 10 ? DateTime.fromMillis(date) : DateTime.fromSeconds(date)
    } else if (date instanceof Date) {
      d = DateTime.fromJSDate(date)
    } else if (typeof date === 'string') {
      // Modify the date string to match ISO 8601 format
      d = DateTime.fromISO(date, { zone: 'local' })
    } else {
      throw new Error('Invalid date format.')
    }
    const now = DateTime.local()
    const diff = now.diff(d, ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']).toObject()

    switch (format) {
      case 'full':
        return d.toLocaleString(DateTime.DATETIME_FULL)
      case 'long':
        return d.toLocaleString(DateTime.DATE_FULL)
      case 'medium':
        return d.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
      case 'short':
        return d.toLocaleString(DateTime.DATE_SHORT)
      case 'monthDay':
        return d.toLocaleString(DateTime.DATE_MED)
      case 'time':
        return d.toLocaleString(DateTime.TIME_SIMPLE)
      case 'ago':
        if (diff.years > 0) {
          // return `${diff.years} yr.${diff.years > 1 ? 's' : ''} ago`
          return d.toLocaleString(DateTime.DATE_SHORT)
        } else if (diff.months > 0) {
          // return `${diff.months} mo.${diff.months > 1 ? 's' : ''} ago`
          return d.toLocaleString(DateTime.DATE_SHORT)
        } else if (diff.weeks > 0) {
          return `${diff.weeks} week${diff.weeks > 1 ? 's' : ''} ago`
        } else if (diff.days > 0) {
          return `${diff.days} day${diff.days > 1 ? 's' : ''} ago`
        } else if (diff.hours > 0) {
          return `${diff.hours} hour${diff.hours > 1 ? 's' : ''} ago`
        } else if (diff.minutes > 0) {
          return `${diff.minutes} minute${diff.minutes > 1 ? 's' : ''} ago`
        } else {
          return 'Just now'
        }
      default:
        throw new Error(`Invalid format: ${format}.`)
    }
  } catch (error) {
    console.error(`Error formatting date ${date} in format ${format}:`, error)
    return ''
  }
}

// export function useFormatGoogleCalendarDate (dateString, allDay) {
//   if (dateString) {
//     if (allDay) {
//       console.log('useFormat $$ allDay', new Date(dateString).toISOString().split('T')[0])
//       return new Date(dateString).toISOString().split('T')[0]
//     } else {
//       console.log('useFormat', new Date(dateString).toISOString())
//       return new Date(dateString).toISOString()
//     }
//   }
// }

export function useFormatGoogleCalendarDate (dateString, allDay) {
  if (!dateString) return null

  const dt = DateTime.fromISO(dateString)

  if (allDay) {
    return dt.setZone(userTimeZone).toISO({ includeOffset: true }).split('T')[0]
  } else {
    return dt.toISO()
  }
}

/**
 *  Converts a number to a formatted currency string.
 *
 *  @param {number} val - The number to format.
 *  @param {string} currencyCode - The currency code to use for formatting (e.g. "USD", "EUR", "JPY").
 *  @param {string} locale - The locale to use for formatting (e.g. "en-US", "fr-FR", "ja-JP").
 *  @param {number} decimalPlaces - amount of decimal places
 *  @returns {string} - The formatted currency string.
 */
export const useFormatCurrency = (val, decimalPlaces, currencyCode, locale = undefined) => {
  decimalPlaces = decimalPlaces ? decimalPlaces : 2
  const userLocale = navigator.language || navigator.userLanguage || 'en-US'

  const currencySymbolMap = {
    USD: '$',
    EUR: '€',
    JPY: '¥',
    GBP: '£',
    CHF: 'CHF',
    CAD: 'CA$',
    AUD: 'A$',
    NZD: 'NZ$',
    CNY: '¥',
    HKD: 'HK$',
    MXN: 'MXN$' // Add Mexican peso (MXN) symbol
  }

  const defaultCurrencyCode = currencyCode || 'USD'
  const defaultLocale = locale || userLocale

  const currencySymbol = currencySymbolMap[defaultCurrencyCode] || '$' // Default to USD symbol
  const formatOptions = {
    style: 'currency',
    currency: defaultCurrencyCode,
    currencyDisplay: 'symbol',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }

  return new Intl.NumberFormat(defaultLocale, formatOptions).format(val).
    replace(currencySymbolMap[defaultCurrencyCode], currencySymbol)
}

/**
 * Toggles an item in an array. If the item is present, it will be removed. If not, it will be added.
 *
 * @param {Array} array - The array to toggle the item in.
 * @param {*} val - The value to toggle in the array.
 */
export function useToggleArrayItem (array, val) {
  let index = null
  if (typeof val === 'object') {
    const newVal = val?.name || val?.val || val?.value || val?.id || val?.uid
    index = array.findIndex(e => (e.name || e.val || e.value || e.id || val?.uid) === newVal)
    if (index > -1) {
      /* array contains the element we're looking for, at index */
      array.splice(index, 1)
    } else {
      array.push(val)
    }
  } else if (array.includes(val)) {
    index = array.indexOf(val)
    array.splice(index, 1)
  } else {
    array.push(val)
  }
}

/**
 *  Returns the corresponding tailwind CSS color class for a given support ticket tag.
 *
 *  @param {string} tag - The tag of the support ticket.
 *  @returns {string} - The corresponding tailwind CSS color class for the tag.
 */
export function useTagColor (tag) {
  switch (tag) {
    case 'account_issue':
      return 'bg-red-500'
    case 'app_crash':
      return 'bg-orange-500'
    case 'billing_issue':
      return 'bg-amber-500'
    case 'bug_report':
      return 'bg-yellow-500'
    case 'configuration_support':
      return 'bg-lime-500'
    case 'estimate_delivery':
      return 'bg-green-500'
    case 'feature_request':
      return 'bg-emerald-500'
    case 'login_problem':
      return 'bg-teal-500'
    case 'not_tradebox_issue':
      return 'bg-cyan-500'
    case 'product_feedback':
      return 'bg-blue-500'
    case 'urgent':
      return 'bg-rose-500'
    case 'invoice_delivery':
      return 'bg-rose-500'
    default:
      return 'bg-red-500'
  }
}

/**
 *  Returns the initials of a given name as a string of uppercase letters.
 *
 *  @param {string} name - The name to get the initials from.
 *  @returns {string} - The initials of the given name in uppercase letters.
 */
export function useGetInitials (name) {
  if (typeof name === 'string' && name.trim() !== '') {
    const initials = name.match(/\b\w/g) || []
    return initials.join('').toUpperCase()
  } else {
    return ''
  }
}

/**
 *  Formats a number of bytes to human-readable format
 *
 *  @param {number} bytes - Number of bytes to format
 *  @param {number} decimals - Number of decimal places to include (default 2)
 *  @returns {string} - Human-readable string representation of the bytes
 */
export function useFormatBytes (bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'
  if (!bytes) return ''
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function useHexToRgb (hex, opacity) {
  if (!hex) return null // add this line to check for null or undefined
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

/**
 * Scrolls to the element with the provided class, id or element.
 *
 * @param {string | HTMLElement} target - The class, id or element to scroll to.
 * @param {ScrollBehavior | undefined} [behavior='smooth'] - The scrolling behavior to use.
 */
export function useScrollTo (target, behavior = 'smooth') {
  let el
  if (typeof target === 'string') {
    el = document.querySelector(`.${target}`)
    if (!el) {
      el = document.querySelector(`#${target}`)
    }
  } else if (target instanceof HTMLElement) {
    el = target
  }
  if (el) {
    el.scrollIntoView({ behavior })
  }
}

export function toCapitalize (str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function useRemToPx (remValue) {
  const remInPx = parseFloat(getComputedStyle(document.documentElement).fontSize)
  return remValue * remInPx
}

export function useHtmlToText (html) {
  return html.replace(/<br>/g, '\n').replace(/<[^>]*>/g, ' ')
}

export async function useOpenEmailFromTemplate (template, type, to, docUid, companyUid) {
  const typeShort = type.slice(0, 3)
  const link = await useCreateClientURL(typeShort, docUid, companyUid)

  console.log('link', link)
  console.log('template', template)

  const subject = template?.subject || ''
  const body = template?.body ? template?.body?.replace(/\n/g, '%0D%0A') +
      '%0D%0A%0D%0A' + capitalize(type) + ' link:%0D%0A%0D%0A' + encodeURIComponent(link) : '' +
      '%0D%0A%0D%0A' + capitalize(type) + ' link:%0D%0A%0D%0A' + encodeURIComponent(link)

  const emailUrl = `mailto:${to}?subject=${subject}&body=${body}`

  try {
    window.location.href = emailUrl
  } catch (error) {
    console.error(error)
  }
}

export async function useOpenSMS(type, to, docUid, companyUid) {
  const typeShort = type.slice(0, 3)
  const link = useCreateClientURL(typeShort, docUid, companyUid)

  const body = capitalize(type) + ' link: ' + encodeURIComponent(link)

  const url = `sms:+1${to}&body=${body}`

  try {
    window.location.href = url
  } catch (error) {
    console.error(error)
  }
}

export function useCreateClientURL (type, docUid, companyUid) {
  const url = new URL(`https://tradeboxpro.app/c2-${type}`)
  const params = new URLSearchParams(url.search)
  params.set(`${type}`, docUid)
  params.set('co', companyUid)
  url.search = params.toString()

  console.log(url.toString())
  return url.toString()
}

function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
