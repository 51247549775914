import { Loader } from '@googlemaps/js-api-loader'
import { cloneDeep } from 'lodash'
import { address as addressRef } from '@/utils/index.js'

// paintbox maps api key: AIzaSyAxULs4tuSygMRf8S6QpFwWWbN3B_-1IkU
// tradebox: AIzaSyCitwPRGdrtwuNtiVCnghsTtgTmiuwkMvU
const loader = new Loader({
  apiKey: 'AIzaSyAxULs4tuSygMRf8S6QpFwWWbN3B_-1IkU',
  version: 'weekly',
  libraries: ['places']
})

const fetchAddress = (lat, lng, address) => {
  if ((!lat && !lng) && !address) {
    throw new Error('Either latitude and longitude or address should be provided')
  }

  return loader.load().then(() => {
    const geocoder = new google.maps.Geocoder()
    let geocodePromise = null

    if (lat && lng) {
      const latLng = new google.maps.LatLng(lat, lng)
      geocodePromise = new Promise((resolve, reject) => {
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              resolve(results[0])
            } else {
              reject(new Error('No results found'))
            }
          } else {
            reject(new Error(`Geocoder failed due to: ${status}`))
          }
        })
      })
    } else if (address) {
      geocodePromise = new Promise((resolve, reject) => {
        geocoder.geocode({ address }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              resolve(results[0])
            } else {
              reject(new Error('No results found'))
            }
          } else {
            reject(new Error(`Geocoder failed due to: ${status}`))
          }
        })
      })
    }

    return geocodePromise.then((place) => {
      const obj = cloneDeep(addressRef)

      obj.formatted = place?.formatted_address
      obj.street = place?.address_components.find(c => c.types.includes('street_number'))?.long_name + ' ' +
        place.address_components.find(c => c.types.includes('route'))?.long_name
      obj.lat = place.geometry.location.lat()
      obj.long = place.geometry.location.lng()

      place.address_components.forEach((c) => {
        switch (c.types[0]) {
          case 'sublocality_level_1': //  Bronx
            obj.area = c.short_name
            break
          case 'locality': //  Los Angeles
            obj.city = c.short_name
            break
          case 'administrative_area_level_1': // Some countries NO states
            obj.state = c.short_name
            break
          case 'postal_code': // Zip Code
            obj.zip = c.short_name
            break
          case 'country': // Country
            obj.country = c.short_name
            break
        }
      })

      return obj
    })
  })
}

export { loader, fetchAddress }
