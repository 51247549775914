import {acceptHMRUpdate, defineStore} from 'pinia'
import {useUid} from '@/utils'
import {
  deleteFirebaseSubcollectionDocumentField,
  getFirebaseSubCollectionDocument,
  getLiveFirebaseDocument,
  setFirebaseDocument,
  setFirebaseSubcollectionDocument,
  storage,
  updateFirebaseSubcollectionDocumentField
} from '@/apis'
import {deleteObject, ref} from 'firebase/storage'
import {useGlobalStore} from '@/stores/useGlobalStore.js'
import {useUserStore} from '@/stores/useUserStore.js'

export const useResourceStore = defineStore('resources', {
  state: () => ({
    items: new Map(),
    links: new Map(),
    tax_rates: new Map(),
    documents: new Map(),
    attachments: new Map(),
    email_templates: new Map(),
    support_tickets: new Map(),
    hasFetchedTickets: false,
    listeners: []
  }),

  getters: {

    fetchSupportTicket (state) {
      return (uid) => state.support_tickets.get(uid)
    },

    supportTicketsSorted (state) {
      let sortedArray = []
      if (state.support_tickets) {
        sortedArray = [...state.support_tickets.values()]
        sortedArray.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      }
      return sortedArray
    },

    estimateItemTagList () {
      const tags = new Set()
      const array = Array.from(this.items.values())
      array.forEach((item) => {
        if (item?.tags && item.tags.length) {
          item.tags.forEach((tag) => tag && tags.add(tag))
        }
      })
      return Array.from(tags).map((tag) => (tag))
    }
  },

  actions: {
    async fetchCompanyDocuments (docID) {
      const docData = await getFirebaseSubCollectionDocument(
        'companies',
        `${docID}`,
        'resources',
        `${docID}`
      )

      if (!docData) {
        const data = { attachments: {}, documents: {}, email_templates: {}, links: {} }

        await setFirebaseSubcollectionDocument(
          'companies',
          `${docID}`,
          'resources',
          `${docID}`,
          data
        )
      } else {
        this.attachments = this.setMapWithItems(this.attachments, docData?.attachments || {})

        this.documents = this.setMapWithItems(this.documents, docData?.documents || {})

        this.links = this.setMapWithItems(this.links, docData?.links || {})

        this.email_templates = this.setMapWithItems(this.email_templates, docData?.email_templates || {})
      }

    },


    async fetchUserItems (docID) {
      const docData = await getFirebaseSubCollectionDocument(
        'companies',
        `${docID}`,
        'items',
        `${docID}`
      )

      if (!docData) {
        const data = { items: {}, tax_rates: {} }

        await setFirebaseSubcollectionDocument(
          'companies',
          `${docID}`,
          'items',
          `${docID}`,
          data
        )
      } else {
        this.items = this.setMapWithItems(this.items, docData?.items || {}, 'product.name')

        this.tax_rates = this.setMapWithItems(this.tax_rates, docData?.tax_rates || {}, 'name')
      }
    },

    setMapWithItems(map, items, sortBy = 'created_at') {
      const newMap = new Map();
      if (typeof items === 'object') {
        // Convert the object to an array of entries
        const entries = Object.entries(items);

        // Sort the entries based on sortBy
        const sortedEntries = entries.sort((a, b) => {
          const [uidA, itemA] = a;
          const [uidB, itemB] = b;

          let valueA, valueB;

          // Get the values to compare based on sortBy
          switch (sortBy) {
            case 'name':
              valueA = itemA.name;
              valueB = itemB.name;
              break;
            case 'product.name':
              valueA = itemA.product?.name;
              valueB = itemB.product?.name;
              break;
            default:
              valueA = uidA;
              valueB = uidB;
          }

          // Ensure that we're comparing strings, then sort
          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB);
          }

          // Fallback for non-string values or undefined
          return (valueA ?? '') > (valueB ?? '') ? 1 : -1;
        });

        // Populate the new map with the sorted entries
        sortedEntries.forEach(([uid, item]) => {
          newMap.set(uid, item);
        });
      }
      return newMap;
    },

    async updateItemTags (company_uid, collectionName, field, tags, array) {
      const newArray = Array.from(array)
      const tagArray = Array.isArray(tags) ? tags : [tags] // Convert tags to an array if it's not already
      const promises = newArray.map(async (item) => {
        const itemTags = Array.isArray(item.tags) ? item.tags : [] // Convert item tags to an array if it's not already
        const newTags = tagArray.filter((tag) => !itemTags.includes(tag)) // Filter out the tags that already exist in
                                                                          // the item tags
        if (newTags.length === 0) {
          return // No new tags to add, skip to next item
        }
        item.tags = [...itemTags, ...newTags] // Merge existing tags and new tags
        await this.updateField(company_uid, collectionName, field, item)
      })
      try {
        await Promise.all(promises)
        return { success: true }
      } catch (error) {
        console.log(error)
      }
    },

    async updateField (company_uid, collectionName, field, data) {
      const globalStore = useGlobalStore()
      if (!data.uid) data.uid = useUid()
      try {
        await updateFirebaseSubcollectionDocumentField('companies',
          `${company_uid}`,
          `${collectionName}`,
          `${company_uid}`,
          `${field}.${data.uid}`,
          data
        )

        this[field].set(data.uid, data)

        globalStore.openNotify(
          'success',
          'Success',
          'Your item has been successfully saved.'
        )

        return { message: 'success', success: true, value: data }
      } catch (e) {
        return { message: e.message, error: true }
      }
    },

    async deleteField (company_uid, collectionName, field, data) {
      const globalStore = useGlobalStore()
      try {
        await deleteFirebaseSubcollectionDocumentField(
          'companies',
          `${company_uid}`,
          `${collectionName}`,
          `${company_uid}`,
          `${field}.${data.uid}`
        )

        this[field].delete(data.uid)

        globalStore.openNotify(
          'success',
          'Success',
          'Your item has been successfully deleted.'
        )
      } catch (e) {
        console.log('error updating field', e)
      }
    },

    async deleteFileFromStorage (company_uid, collectionName, field, value) {
      const storageTitle = value.storage_path
      const docRef = ref(storage, storageTitle)

      return deleteObject(docRef).then(() => {
        this.deleteField(company_uid, collectionName, field, value)
      }).catch((e) => {
        throw new Error(e)
      })
    },

    async submitSupportTicket (value, action) {
      const globalStore = useGlobalStore()
      let message = null
      switch (action) {
        case 'add':
          message = 'Your support ticket has been submitted. Please expect 1-2 business days for response.'
          break
        case 'update':
          message = 'The support ticket has been updated.'
          break
        case 'message':
          message = 'Your message has been sent.'
          break
        case 'resolved':
          message = 'The support ticket has been marked as resolved.'
          break
        case 'reopened':
          message = 'Your support ticket has been reopened.'
          break
        default:
          throw new Error('Invalid action type provided.')
      }
      try {

        await setFirebaseDocument('support_tickets', `${value.user_uid}`, {[value.uid]: value})

        this.support_tickets[value.uid] = value
        globalStore.openNotify('success', 'Success', message)
        return { message: 'success', success: true }
      } catch (e) {
        return { message: e.message, error: true }
      }
    },

    async fetchSupportTickets (docID) {
      const useUser = useUserStore()
      const uid = docID || useUser.user.uid

      if (uid && !this.hasFetchedTickets) {
        const unsubscribe = getLiveFirebaseDocument(
          'support_tickets',
          `${uid}`,
          (doc) => {
            for (const docID in doc) {
              this.support_tickets.set(docID, doc[docID])
            }
          }
        )

        this.hasFetchedTickets = true
        this.listeners.push(unsubscribe)
      }
    },

    /**
     * Unsubscribes all firebase listeners added to the current store array
     * @function
     * @returns {undefined}
     */
    unsubscribe () {
      this.listeners.forEach(fn => fn())
    }

  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useResourceStore, import.meta.hot))
}
