import {acceptHMRUpdate, defineStore} from 'pinia'
import 'cordova-plugin-purchase'
import {Device} from '@capacitor/device'
import {useCompanyStore} from '@/stores/useCompanyStore.js'
import {functions, updateFirebaseDocumentField} from '@/apis/index.js'
import {increment} from 'firebase/firestore'
import {useGlobalStore} from '@/stores/useGlobalStore.js'
import {httpsCallable} from 'firebase/functions'
import {Browser} from '@capacitor/browser'

export const useIAPStore = defineStore('IAP', {
  state: () => ({
    subscriptions: [],
    products: [],
    store: null,
    platform: null,
    purchaseToken: null
  }),

  getters: {},

  actions: {
    async initProducts () {
      const { store, Platform, ProductType } = CdvPurchase
      const deviceInfo = await Device.getInfo()
      const userDevice = deviceInfo.platform
      this.store = store
      const platform = userDevice === 'ios' ? Platform.APPLE_APPSTORE :
        userDevice === 'android' ? Platform.GOOGLE_PLAY
          : null

      const group = 'monthly_subscription'

      const products_to_register = [
        {
          id: 'pro',
          type: ProductType.PAID_SUBSCRIPTION,
          platform,
          group
        },
        {
          id: 'pro_plus',
          type: ProductType.PAID_SUBSCRIPTION,
          platform,
          group
        },
        {
          id: 'premium',
          type: ProductType.PAID_SUBSCRIPTION,
          platform,
          group
        },
        {
          id: 'ai_tokens_250k',
          type: ProductType.CONSUMABLE,
          platform
        }
      ]

      await store.register(products_to_register)
      await this.setupListeners(store)

      const iaptic = new CdvPurchase.Iaptic({
        appName: 'app.tradeboxpro',
        apiKey: 'ff65144c-7f2e-435a-92ae-904987aa64ca'
      })

      store.validator = iaptic.validator
      await store.initialize()
      await this.setUser()

      store.ready(() => {
        // this.products = store.products
        // console.log('store ready')
      })
    },

    setUser (uid) {
      const { store } = CdvPurchase
      const companyStore = useCompanyStore()
      const { company } = companyStore
      uid = uid || company.uid
      store.applicationUsername = uid
    },

    setupListeners (store) {
      store.when().approved(transaction => {
        // console.log('transaction approved', JSON.stringify(transaction))
        transaction.verify()
      }).verified(async (receipt) => {

        // console.log('receipt verified:', JSON.stringify(receipt))
        receipt.finish()

        if (receipt.sourceReceipt.transactions.length > 0) {
          const lastReceipt = receipt.sourceReceipt.transactions.pop()
          // console.log('lastReceipt:', JSON.stringify(lastReceipt))

          await this.assignProducts(lastReceipt.products)

          if (receipt.latestReceipt?.nativePurchase?.autoRenewing) {
            this.purchaseToken = receipt.sourceReceipt?.purchaseToken
          }
        }

      }).unverified(() => {
        // console.log('receipt unverified:', JSON.stringify(receipt))
      }).productUpdated(product => {
        const index = this.products.findIndex(p => p.id === product.id)
        // console.log('product.type:', JSON.stringify(product.type))

        const targetArray = (product.type === 'paid subscription') ? this.subscriptions : this.products
        
        if (index !== -1) {
          targetArray[index] = product
        } else {
          targetArray.push(product)
        }
      });
    },

    async assignProducts (array) {
      const { company } = useCompanyStore()
      const currentIdx = ['pro', 'pro_plus', 'premium'].indexOf(company?.plan?.type)
      const newID = array[0].id
      const newIdx = ['pro', 'pro_plus', 'premium'].indexOf(newID)

      if ((newIdx > currentIdx) && !['stripe', 'admin'].includes(company?.plan?.provider)) {
        company.plan.type = this.matchPlan(newID)
      } else if (newID === 'ai_tokens_250k') {
        await this.addTokens(250000)
      }
    },

    matchPlan (str) {
      return str ? str.toLowerCase().replace(/-/g, '_') : ''
    },

    async inAppPurchase (planId, company_uid) {
      try {
        const { store, GooglePlay } = CdvPurchase
        const googlePlay = this.purchaseToken ? {
          prorationMode: GooglePlay.ProrationMode.IMMEDIATE_AND_CHARGE_FULL_PRICE,
          oldPurchaseToken: this.purchaseToken
        } : { prorationMode: GooglePlay.ProrationMode.IMMEDIATE_AND_CHARGE_FULL_PRICE }

        await this.setUser(company_uid)
        await store.get(planId).getOffer().order({ applicationUsername: company_uid, googlePlay })
      } catch (e) {
        console.log('ERROR. Failed to find product order. ' + e.code + ': ' + e.message)
      }
    },

    async purchaseTokens (id, company_uid) {
      const useGlobal = useGlobalStore()
      try {
        useGlobal.isPending = true
        const { store } = CdvPurchase
        const product = this.products.find(p => p.id.toLowerCase() === id.toLowerCase())
        const offer = product.offers[0]
        await store.order(offer, { applicationUsername: company_uid })
      } catch (e) {
        console.log('ERROR. Failed to find product order. ' + e.code + ': ' + e.message)
      } finally {
        useGlobal.isPending = false
      }
    },

    async stripePurchaseTokens () {
      try {
        const priceId = 'price_1NizxLGfXSKs7HANwc3xxnK1'
        // const test_price_id = 'price_1NaJv8GfXSKs7HANnAW5P8x4'
        const callable = httpsCallable(functions, 'stripeRedirectToCheckout')
        const response = await callable(
          {
            priceId,
            mode: 'payment',
            success_url: 'https://tradeboxpro.app/',
            cancel_url: 'https://tradeboxpro.app/'
          })
        if (response.data) {
          await Browser.open({ url: response.data })
        }
      } catch (e) {
        throw new Error('Error purchasePlan ->' + e)
      }
    },

    async addTokens (n) {
      const companyStore = useCompanyStore()
      const { company } = companyStore
      await updateFirebaseDocumentField(
        'companies',
        `${company.uid}`,
        'reference.ai.available_tokens',
        increment(n)
      )
    },

    async manageSubscriptions () {
      const { store } = CdvPurchase
      store.manageSubscriptions()
    },

    async restorePurchase () {
      const { store } = CdvPurchase
      await store.restorePurchases()
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useIAPStore, import.meta.hot))
}
