<script setup>
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

defineProps({
  modelValue: {
    type: [Number, String, Object],
    default: null
  },
  label: {
    type: String,
    default: ''
  },
  options: {
    type: Array,
    default () {
      return []
    }
  }
})

const emit = defineEmits(['update:modelValue', 'change'])

function selectItem (option) {
  emit('update:modelValue', option.value || option)
  emit('change', option.value || option)
}
</script>

<template>
  <RadioGroup :value="modelValue">
    <!-- Label -->
    <RadioGroupLabel class="text-sm font-medium leading-6 text-gray-900 dark:text-white">
      {{ label }}
    </RadioGroupLabel>

    <!-- Radio options container -->
    <!-- options.length === 2 ? 'sm:grid-cols-2' : 'sm:grid-cols-3', -->
    <div

      :class="[ 'mt-4 grid grid-cols-1 gap-y-6 sm:gap-x-4']"
    >
      <!-- Radio options -->
      <template
        v-for="option in options"
        :key="option"
      >
        <RadioGroupOption
          v-if="!option.hide"
          v-slot="{ checked }"
          :value="option.value || option"
          as="template"
          @click="selectItem(option)"
        >
          <div
            :class="[checked || modelValue === option.value ? 'border-transparent' : 'border-gray-900/10 dark:border-white/20', 'relative flex cursor-pointer rounded-lg border bg-white dark:bg-gray-950 p-4 shadow-sm focus:outline-none']"
          >
            <span class="flex flex-1">
              <span class="flex flex-col">
                <!-- Label -->
                <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900 dark:text-white">
                  {{ option.label || option }}
                </RadioGroupLabel>

                <!-- Description -->
                <RadioGroupDescription
                  as="span"
                  class="mt-1 flex items-center text-sm text-gray-500"
                >
                  {{ option.desc || "" }}
                </RadioGroupDescription>
              </span>
            </span>

            <!-- Check icon if selected -->
            <CheckCircleIcon
              :class="[checked || modelValue === option.value? 'text-blue-600' : 'invisible', 'h-5 w-5']"
              aria-hidden="true"
            />
            <span
              :class="[checked || modelValue === option.value ? 'border-blue-500 border-2' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']"
              aria-hidden="true"
            />
          </div>
        </RadioGroupOption>
      </template>
    </div>
  </radiogroup>
</template>
