import {acceptHMRUpdate, defineStore} from 'pinia'
import {collection, doc, increment, onSnapshot, orderBy, query} from 'firebase/firestore'
import {useGlobalStore} from '@/stores/useGlobalStore.js'
import {
  calculateDocumentSize,
  deleteFirebaseSubcollectionDocumentField,
  firebaseDb,
  setFirebaseSubcollectionDocument,
  updateFirebaseDocumentField,
  updateFirebaseSubcollectionDocumentField
} from '@/apis'
import {useUserStore} from '@/stores/useUserStore.js'
import {useUid} from '@/utils/index.js'

export const useClientStore = defineStore('clients', {
  state: () => ({
    clients: new Map(),
    listeners: [],
    sortBy: 'createdAt',
    input: '',
    clientDocUid: ''
  }),

  getters: {
    groupedClients(state) {
      const searchTerm = state.input.toLowerCase()
      const clients = Array.from(useClientStore().clients.values()) || []
      const filteredClients = clients.filter(client => {
        const nameMatch = client.name?.toLowerCase().includes(searchTerm)
        const emailMatch = client.email?.toLowerCase().includes(searchTerm)
        const addressMatch = client.address?.formatted?.toLowerCase().includes(searchTerm)

        return nameMatch || emailMatch || addressMatch
      })

      const groupedFilteredClients = filteredClients.reduce((acc, client) => {
        const firstLetter = client.name?.charAt(0).toUpperCase()
        acc[firstLetter] = [...(acc[firstLetter] || []), client]
        return acc
      }, {})

      return Object.fromEntries(Object.entries(groupedFilteredClients).sort())
    }
  },

  actions: {
    fetchClients(companyUid) {
      const userStore = useUserStore()
      companyUid = companyUid || userStore.user.company_uid
      this.clientDocUid = companyUid
      const subcollectionRef = collection(doc(firebaseDb, 'companies', companyUid), 'clients')
      // Query the subcollection to retrieve documents in ascending order
      const q = query(subcollectionRef, orderBy('created_at', 'asc'))
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        // if query is empty create document
        if (querySnapshot.empty) {
          await setFirebaseSubcollectionDocument('companies', companyUid, 'clients', this.clientDocUid, {created_at: Date.now()})
        }
        // assign data to clients
        let lastDocData = {}
        querySnapshot.forEach((doc) => {
          const docData = doc.data()
          for (const client of Object.values(docData)) {
            if (client.uid) {
              this.clients.set(client.uid, client)
            }
          }
          lastDocData = docData
        })
        const docSize = calculateDocumentSize(lastDocData)
        if (docSize >= 800000) { // 0.8 MB - 800000 bytes
          this.clientDocUid = useUid()
          await setFirebaseSubcollectionDocument('companies', `${companyUid}`, 'clients', this.clientDocUid, {created_at: Date.now()})
        }

      })

      this.listeners.push(unsubscribe)
    },

    // fetch single client
    fetchClient(uid) {
      return this.clients.get(uid)
    },

    handleNewClient(company) {
      const globalStore = useGlobalStore()
      const {
        reference,
        plan
      } = company
      const period_count = reference?.client?.period_count

      if (plan === 'free' && period_count >= 5) {
        globalStore.openDialog('warning', 'Upgrade to Unlock Clients', 'You have reached your monthly limit for adding clients. Upgrade to access additional client management capabilities.', {
          name: 'View Plans',
          action: () => this.router.push('/billing')
        })
        return false
      } else {
        return true
      }
    },

    // Update client
    async updateClient(data) {
      const globalStore = useGlobalStore()

      const companyUid = data.company_uid
      let action = 'update'

      if (!data.created_at) {
        action = 'add'
        data.created_at = Date.now()
        data.doc_uid = this.clientDocUid
      }

      const text = `Your client has been ${action === 'add' ? 'saved' : 'updated'}.`
      try {
        await updateFirebaseSubcollectionDocumentField('companies', `${companyUid}`, 'clients', `${data.doc_uid}`, `${data.uid}`, data)

        if (action === 'add') {
          await updateFirebaseDocumentField('companies', `${data.company_uid}`, 'reference.client.period_count', increment(1))
        }

        globalStore.openNotify('success', 'Success', text)
        return {
          message: 'success',
          success: true,
          data
        }
      } catch (error) {
        globalStore.openNotify('error', 'Error', 'There was an error saving the client.')
        return {
          success: false,
          message: error.message
        }
      }
    },

    // Delete client
    async deleteClient(data) {
      try {
        await deleteFirebaseSubcollectionDocumentField('companies', data.user_uid, 'clients', data.doc_uid, data.uid)
        return {
          message: 'success',
          success: true
        }
      } catch (error) {
        return {
          message: error.message,
          error: true
        }
      }
    },

    async assignClientStripeData(client_uid) {
      const client = this.clients.get(client_uid)
      console.log('client', client)
      return client.stripe
    },

    // unsubscribe from listeners on auth state change
    unsubscribe() {
      this.listeners.forEach(fn => fn())
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useClientStore, import.meta.hot))
}
