<script setup>
import { ref, watch } from 'vue'
import { usePhone } from '@/utils'
import { XMarkIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null
  },
  label: {
    type: [String, Number],
    default: null
  },
  type: {
    type: String,
    default: 'text'
  },
  required: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  clearable: {
    type: Boolean,
    default: true
  },
  altColor: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

const phoneData = usePhone(props.modelValue)
const phoneRef = phoneData.phoneRef
const formatPhone = phoneData.formatPhone
const input = ref(null)

watch(phoneRef, (newX) => {
  emit('update:modelValue', newX)
})

watch(() => props.modelValue, (newX) => {
  if (newX !== phoneRef.value) phoneRef.value = newX
})
</script>

<template>
  <div class="w-full">
    <!-- screen reader -->
    <label :for="props.label" class="sr-only">
      {{ props.label }}
    </label>

    <!-- Visible label -->
    <div class="flex justify-between items-baseline">
      <label
        v-if="props.label"
        :for="props.label"
        class="block text-sm/6 font-medium text-gray-900 dark:text-white"
      >
        {{ props.label }}
      </label>

      <span class="text-xs text-gray-500">{{ props.required ? 'Required' : '' }}</span>
    </div>

    <div :class="[ props.label ? 'mt-2': null, 'w-full relative']">
      <!-- Prefix -->
      <div
        v-if="$slots.prefix"
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400 dark:text-gray-600"
      >
        <slot name="prefix" />
      </div>

      <!-- Input -->
      <input
        ref="input"
        v-model="phoneRef"
        :class="[
          props.error
            ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
            : 'border-gray-900/20 dark:border-white/10 focus:border-blue-500 focus:ring-blue-500 ',
          props.clearable && 'pr-8',
          props.borderless ? 'border-0' : 'border shadow-sm',
          props.inputClass,
          'block w-full rounded-md bg-white dark:bg-white/5 sm:text-sm resize-none min-h-[38px] h-[38px] text-gray-900 dark:text-white placeholder:text-gray-400 px-2 scroll-bar--none'
        ]"
        :name="label"
        maxlength="14"
        pattern="[0-9]*"
        type="tel"
        v-bind="$attrs"
        @input="formatPhone"
      >

      <!-- Suffix -->
      <div
        v-if="$slots.suffix"
        class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 dark:text-gray-600"
      >
        <slot name="suffix" />
      </div>

      <!-- Clearable -->
      <button
        v-if="clearable && phoneRef"
        class="pointer-events-auto absolute right-0 top-2.5 pr-3"
        type="button"
        @click="()=>{emit('update:modelValue', ''); input.focus()}"
      >
        <XMarkIcon
          aria-hidden="true"
          class="h-4 w-4 text-gray-400 hover:text-gray-600 dark:hover:text-gray-200"
        />
      </button>
    </div>
  </div>
</template>
