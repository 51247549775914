<script setup>
import {onMounted, ref, watch} from 'vue'

const props = defineProps({
  label: {
    type: [String, Number],
    default: ''
  },
  modelValue: {
    type: Boolean,
    required: true
  },
  small: {
    type: Boolean,
    required: false
  }
})

const emit = defineEmits(['update:modelValue'])

const isChecked = ref(false)

onMounted(() => {
  isChecked.value = props.modelValue
})

watch(
    () => props.modelValue,
    () => {
      isChecked.value = props.modelValue
    }
)

function toggle() {
  isChecked.value = !isChecked.value
  emit('update:modelValue', isChecked.value)
}
</script>

<template>
  <div class="flex h-6 items-center">
    <input
      id="comments"
      v-model="isChecked"
      aria-describedby="comments-description"
      class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
      name="comments"
      type="checkbox"
      @click="toggle()"
    >
  </div>
</template>
