import { acceptHMRUpdate, defineStore } from 'pinia'
import { fetchAddress, firebaseDb } from '@/apis'
import { Network } from '@capacitor/network'
import { Geolocation } from '@capacitor/geolocation'
import { disableNetwork, enableNetwork } from 'firebase/firestore'
import { usePlatform } from '@/composables/usePlatform.js'

export const useDeviceStore = defineStore('device', {
  state: () => ({
    GPS: { latitude: null, longitude: null },
    address: {},
    isOnline: false
  }),
  getters: {
    online (state) {return state.isOnline}
  },
  actions: {

    async enableFirestoreNetwork () {
      await this.checkAndEnableFirestoreNetwork()

      Network.addListener('networkStatusChange', async (status) => {
        if (status.connected) {
          await this.enableNetworkConnectivity()
        } else {
          await this.disableNetworkConnectivity()
        }
      })
    },

    async enableNetworkConnectivity () {
      await enableNetwork(firebaseDb)
      this.isOnline = true
    },

    async disableNetworkConnectivity () {
      await disableNetwork(firebaseDb)
      this.isOnline = false
    },

    async checkAndEnableFirestoreNetwork () {
      const status = await Network.getStatus()
      if (status.connected) {
        await this.enableNetworkConnectivity()
      } else {
        await this.disableNetworkConnectivity()
      }
    },

    async fetchAddressFromGPS () {
      try {
        const coords = await this.fetchCurrentPosition()
        const address = await this.fetchAddress(coords.latitude, coords.longitude)
        this.address = address
        return address
      } catch (error) {
        throw new Error('Unable to get address from GPS')
      }
    },

    async fetchCurrentPosition () {
      if (usePlatform.isCapacitor) {
        const permission = await Geolocation.checkPermissions()
        if (permission.location === 'denied') {
          await Geolocation.requestPermissions()
        }
        const { coords } = await Geolocation.getCurrentPosition({ enableHighAccuracy: true })
        this.GPS = coords
        return coords
      } else {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            ({ coords }) => {
              this.GPS = coords
              resolve(coords)
            },
            error => {
              reject(new Error('Unable to get GPS location' + error))
            }
          )
        })
      }
    },

    async fetchAddress (latitude, longitude, address) {
      try {
        return await fetchAddress(latitude, longitude, address)
      } catch (error) {
        throw new Error('Unable to get address from GPS coordinates')
      }
    }

  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDeviceStore, import.meta.hot))
}
