import { reactive, ref } from 'vue'

export function useCalendar () {

  const current = reactive({
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    day: new Date().getDay()
  })

  const weeklyStartDate = ref(new Date(new Date().setDate(new Date().getDate() - new Date().getDay())))

  const selectedDate = ref(new Date().toISOString().slice(0, 10))

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  const dailyTimeSlots = [
    '12AM', '1AM', '2AM', '3AM', '4AM', '5AM', '6AM', '7AM', '8AM', '9AM', '10AM', '11AM',
    '12PM', '1PM', '2PM', '3PM', '4PM', '5PM', '6PM', '7PM', '8PM', '9PM', '10PM', '11PM'
  ]

  function resetCurrentDates () {
    const today = new Date()
    current.year = today.getFullYear()
    current.month = today.getMonth()
    current.day = today.getDay()
    selectedDate.value = new Date().toISOString().slice(0, 10)
    weeklyStartDate.value = new Date(today.setDate(today.getDate() - today.getDay()))
  }

  // formatting
  function monthToLongFormatDate (monthNumber) {
    const date = new Date(0, monthNumber)
    return date.toLocaleString('default', { month: 'long' })
  }

  function formatTime (value) {
    const date = new Date(value)
    return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })
  }

  function usePrevCalendarPeriod (view) {
    if (view === 'Month') {
      if (current.month === 0) {
        current.month = 11
        current.year--
      } else {
        current.month--
      }
    } else if (view === 'Week') {
      const prevMonth = new Date(weeklyStartDate.value)
      prevMonth.setDate(prevMonth.getDate() - 7)
      if (prevMonth.getMonth() !== weeklyStartDate.value.getMonth()) {
        current.month = prevMonth.getMonth()
        current.year = prevMonth.getFullYear()
      }
      weeklyStartDate.value = prevMonth
    }
  }

  function useGoToToday () {
    const today = new Date()
    current.year = today.getFullYear()
    current.month = today.getMonth()
    current.day = today.getDay()
    weeklyStartDate.value = new Date(today.setDate(today.getDate() - today.getDay()))
    selectedDate.value = new Date().toISOString().slice(0, 10)
  }

  function useNextCalendarPeriod (view) {
    if (view === 'Month') {
      if (current.month === 11) {
        current.month = 0
        current.year++
      } else {
        current.month++
      }
    } else if (view === 'Week') {
      const nextWeek = new Date(weeklyStartDate.value)
      nextWeek.setDate(nextWeek.getDate() + 7)
      if (nextWeek.getMonth() !== weeklyStartDate.value.getMonth()) {
        current.month = nextWeek.getMonth()
        current.year = nextWeek.getFullYear()
      }
      weeklyStartDate.value = nextWeek
    }
  }

  async function generateDateArray (year, month) {

    year = year ? year : new Date().getFullYear()
    month = month ? month : new Date().getMonth()

    const startDate = new Date(year, month, 1)
    const endDate = new Date(year, month + 1, 0)
    const today = new Date()
    const daysInMonth = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1
    const dateArray = []

    for (let i = 0; i < daysInMonth; i++) {
      const currentDate = new Date(year, month, i + 1)

      dateArray.push({
        date: currentDate.toISOString().split('T')[0],
        isCurrentMonth: true,
        isToday: isSameDay(today, currentDate),
        isSelected: isSameDay(today, currentDate),
        events: []
      })
    }

    // Calculate padding days needed
    const startWeekday = startDate.getDay()
    const daysInWeek = 7
    const weeksInCalendar = 6
    const totalDaysInCalendar = daysInWeek * weeksInCalendar
    const daysAdded = daysInMonth + startWeekday
    const paddingDays = totalDaysInCalendar - daysAdded

    // Add padding days to the beginning of the dateArray
    for (let i = 1; i <= startWeekday; i++) {
      dateArray.unshift({
        date: new Date(year, month, -i + 1).toISOString().split('T')[0],
        isCurrentMonth: false,
        isToday: false,
        isSelected: false,
        events: []
      })
    }

    // Add padding days to the end of the dateArray
    for (let i = 1; i <= paddingDays; i++) {
      dateArray.push({
        date: new Date(year, month + 1, i + daysInMonth).toISOString().split('T')[0],
        isCurrentMonth: false,
        isToday: false,
        isSelected: false,
        events: []
      })
    }

    return dateArray
  }

  function isSameDay (date1, date2) {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    )
  }

  return {
    current,
    weeklyStartDate,
    selectedDate,
    daysOfWeek,
    dailyTimeSlots,
    resetCurrentDates,
    monthToLongFormatDate,
    formatTime,
    usePrevCalendarPeriod,
    useGoToToday,
    useNextCalendarPeriod,
    generateDateArray
  }
}
