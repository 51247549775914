<script setup>
import { onMounted, ref, watch } from 'vue'
import { Switch } from '@headlessui/vue'

const props = defineProps({
  label: {
    type: [String, Number],
    default: ''
  },
  modelValue: {
    type: Boolean,
    required: false
  },
  small: {
    type: Boolean,
    required: false
  }
})

const emit = defineEmits(['update:modelValue', 'toggle'])

const isToggled = ref(false)

onMounted(() => {
  isToggled.value = props.modelValue
})

watch(
  () => props.modelValue,
  () => {
    isToggled.value = props.modelValue
  }
)

function toggle () {
  isToggled.value = !isToggled.value
  // emit('update:modelValue', isToggled.value)
  emit('toggle', isToggled.value)
}
</script>

<template>
  <Switch
    v-model="isToggled"
    :class="[
      props.modelValue ? 'bg-blue-600' : 'bg-gray-200 dark:bg-white/20',
      props.small ? 'h-5 w-10' : 'h-6 w-11',
      'relative inline-flex flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-auto focus:ring-offset-white dark:focus:ring-offset-gray-950',
    ]"
    @click="toggle"
  >
    <span
      :class="[
        modelValue ? 'translate-x-5' : 'translate-x-0',
        props.small ? 'h-4 w-4' : 'h-5 w-5',
        'inline-block  transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
      ]"
      aria-hidden="true"
    />
  </Switch>
</template>
