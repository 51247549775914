import { acceptHMRUpdate, defineStore } from 'pinia'
import { deleteFirebaseSubcollectionDocument, getLiveFirebaseSubcollection, updateFirebaseDocumentField } from '@/apis'
import { useUserStore } from '@/stores/useUserStore.js'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { Badge } from '@capawesome/capacitor-badge'

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    notifications: [],
    listeners: []
  }),

  getters: {
    notificationsSorted (state) {
      return state.notifications.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    }
  },

  actions: {
    async fetchNotifications (docId) {
      const unsubscribe = getLiveFirebaseSubcollection(
        'companies',
        `${docId}`,
        'notifications',
        (data) => {
          if (data) {
            this.notifications = data
            return data
          }
        })

      this.listeners.push(unsubscribe)
    },

    async deleteNotification (value) {
      const { user } = useUserStore()
      const index = this.notifications.indexOf(value)
      this.notifications.splice(index, 1)
      // delete from firebase
      await deleteFirebaseSubcollectionDocument('companies', user.company_uid, 'notifications', value.uid)
      // set badge count
      const count = this.notifications?.length
      const { isSupported } = await Badge.isSupported()
      if (isSupported) await Badge.set({ count })
    },

    async registerNotifications () {
      const { user } = useUserStore()
      const { receive } = await FirebaseMessaging.checkPermissions()

      let status
      switch (receive) {
        case 'denied':
          throw new Error('User denied permissions!')
          break
        case 'granted':
          status = 'granted'
          break
        default:
          status = await FirebaseMessaging.requestPermissions()
          break
      }

      if (status === 'granted') {
        const result = await FirebaseMessaging.getToken()
        if (result.token !== user.device) {
          await updateFirebaseDocumentField('users', user.uid, 'device', result.token)
        }
        return result.token
      }
    },

    async getDeliveredNotifications () {
      const result = await FirebaseMessaging.getDeliveredNotifications()
      return result.notifications
    },

    async removeNotifications () {
      await FirebaseMessaging.removeAllDeliveredNotifications()
    },

    // unsubscribe from listeners on auth state change
    async unsubscribe () {
      this.listeners.forEach(fn => fn())
      await FirebaseMessaging.removeAllListeners()
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useNotificationStore, import.meta.hot)
  )
}
