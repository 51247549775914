import {reactive} from 'vue'
import {Keyboard} from '@capacitor/keyboard'
import {usePlatform} from '@/composables/usePlatform'

export default function useScreen () {

  const screen = reactive({
    width: 0,
    height: 0,
    is: {
      xs: true,
      sm: false,
      md: false,
      lg: false,
      xl: false
    },
    gt: {
      xs: false,
      sm: false,
      md: false,
      lg: false,
      xl: false
    },
    lt: {
      xs: false,
      sm: false,
      md: false,
      lg: false,
      xl: false
    }
  })

  const viewport = reactive({
    height: 0,
    keyboardHeight: 0,
    browserBarHeight: 0,
    viewHeight: 0,
    isPending: false
  })

  const keyboard = reactive({
    height: 0
  })

  const breakpoints = {
    xs: 640, sm: 768, md: 1024, lg: 1280, xl: 1536
  }

  const updateViewportHeight = (keyboardHeight) => {
    keyboardHeight = typeof keyboardHeight === 'number' ? keyboardHeight : 0
    viewport.keyboardHeight = keyboardHeight || 0
    screen.width = window.innerWidth
    screen.height = window.innerHeight
    keyboard.height = keyboardHeight || 0
    viewport.height = window.innerHeight
    setScreenSizes()

    const viewportHeight = window.visualViewport ? window.visualViewport.height : window.innerHeight
    const appleApp = usePlatform.isCapacitor && usePlatform.device === 'apple'

    viewport.viewHeight = viewportHeight - viewport.browserBarHeight - (appleApp ? 36 : 0)
    const element = document.querySelector('.chat-button');
    if (element && element?.style) {
      element.style.bottom = '1rem';
      element.style.right = '1rem';
    }
  }

  const updateBrowserBarHeight = () => {
    viewport.browserBarHeight = viewport.height - window.innerHeight

  }

  const setSizes = (val) => {
    Object.keys(screen.is).forEach(key => {
      if (key === val) {
        screen.is[key] = true
        screen.gt[key] = false
        screen.lt[key] = false
      } else if (screen.width > breakpoints[key]) {
        screen.is[key] = false
        screen.gt[key] = true
        screen.lt[key] = false
      } else {
        screen.is[key] = false
        screen.gt[key] = false
        screen.lt[key] = true
      }
    })
  }

  const setScreenSizes = () => {
    if (screen.width <= breakpoints.xs) {
      setSizes('xs')
    } else if (screen.width >= breakpoints.xs && screen.width <= breakpoints.sm) {
      setSizes('sm')
    } else if (screen.width >= breakpoints.sm && screen.width <= breakpoints.md) {
      setSizes('md')
    } else if (screen.width >= breakpoints.md && screen.width <= breakpoints.lg) {
      setSizes('lg')
    } else {
      setSizes('xl')
    }
  }

  let isInitialized = false
  const initData = () => {
    updateViewportHeight()
    isInitialized = true
  }

  if (!isInitialized) {
    initData()
  }


  window.addEventListener('statusTap', updateBrowserBarHeight)
  window.addEventListener('resize', updateViewportHeight)

  if (usePlatform.isCapacitor) {
    Keyboard.addListener('keyboardDidShow', info => {
      updateViewportHeight(info.keyboardHeight)
      viewport.isPending = false
    })
    Keyboard.addListener('keyboardWillShow', info => {
      updateViewportHeight(info.keyboardHeight)
      viewport.isPending = true
    })
    Keyboard.addListener('keyboardDidHide', () => {
      updateViewportHeight(0)
      setTimeout(() => {
        viewport.isPending = false
      }, 300)
    })
    Keyboard.addListener('keyboardWillHide', () => {
      updateViewportHeight(0)
      viewport.isPending = true
    })
  } else {
    window.addEventListener('keyboardDidShow', () => {
      const keyboardHeight = viewport.height - window.innerHeight
      updateViewportHeight(keyboardHeight)
      viewport.isPending = false
    })

    window.addEventListener('keyboardDidHide', () => {
      updateViewportHeight(0)
      viewport.isPending = true
    })
  }

  const removeListeners = async () => {
    if (usePlatform.isCapacitor) {
      await Keyboard.removeAllListeners()
    } else {
      window.removeEventListener('keyboardDidShow', () => {
        const keyboardHeight = viewport.height - window.innerHeight
        updateViewportHeight(keyboardHeight)
        viewport.isPending = false
      })

      window.addEventListener('keyboardDidHide', () => {
        updateViewportHeight(0)
        viewport.isPending = false
      })
    }
  }

  return { screen, viewport, keyboard, removeListeners }
}
