import {cloneDeep} from 'lodash'
import {acceptHMRUpdate, defineStore} from 'pinia'
import {collection, doc, increment, onSnapshot} from 'firebase/firestore'
import {invoice as invoiceRef} from '@/utils'
import {
  firebaseDb,
  functions,
  httpsCallable,
  setFirebaseDocument,
  setFirebaseSubcollectionDocument
} from '@/apis'
import {useGlobalStore} from '@/stores/useGlobalStore.js'
import {useCompanyStore} from '@/stores/useCompanyStore.js'
import {useClientStore} from '@/stores/useClientStore.js';

export const useInvoiceStore = defineStore('invoice', {
  state: () => ({
    invoices: new Map(),
    sortBy: 'createdAt',
    sortByStatus: 'all',
    invoice: cloneDeep(invoiceRef),
    invoiceClone: cloneDeep(invoiceRef),
    duplicateInvoice: null,
    statDays: 7,
    isPending: false,
    listeners: []
  }),

  getters: {
    sortedInvoices (state) {
      const invoices = Array.from(state.invoices.values())
      if (state.sortBy === 'name') {
        return invoices.sort((a, b) => {
          if (a.client.name > b.client.name) return -1
          if (a.client.name < b.client.name) return 1
          else return 0
        })
      }
      if (state.sortBy === 'createdAt') {
        return invoices.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      }
    },

    recentInvoices () {
      const groupedInvoices = []

      this.sortedInvoices.forEach(invoice => {
        const dateNow = new Date()
        const createdDate = new Date(invoice.created_at)
        const diffTime = Math.abs(dateNow - createdDate)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

        if (diffDays <= 7) {
          const date = createdDate.toLocaleDateString('en-US', {
            weekday: 'long',
            month: 'long',
            day: 'numeric'
          })

          // check if there is an existing group for this date
          const existingGroup = groupedInvoices.find(group => group.date === date)

          // if there is, add the invoice to the existing group if it has not reached the maximum number of invoices
          if (existingGroup) {
            existingGroup.items.push(invoice)
          } else {
            // otherwise, create a new group for this date and add the invoice
            groupedInvoices.push({
              date,
              dateTime: invoice.created_at,
              items: [invoice]
            })
          }
        }
      })
      return groupedInvoices
    },

    invoiceByStatus (state) {
      const dateNow = new Date()
      const filteredInvoices = this.sortedInvoices.filter((doc) => {
        const createdDate = new Date(doc.created_at)
        const diffTime = Math.abs(dateNow - createdDate)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return diffDays <= state.statDays
      })

      return {
        all: filteredInvoices,
        draft: filteredInvoices.filter((doc) => doc.status === 'draft'),
        open: filteredInvoices.filter((doc) => doc.status === 'open'),
        paid: filteredInvoices.filter((doc) => doc.status === 'paid'),
        pastDue: filteredInvoices.filter((doc) => doc.status === 'past due'),
        void: filteredInvoices.filter((doc) => doc.status === 'void'),
        uncollectible: filteredInvoices.filter((doc) => doc.status === 'uncollectible')
      }
    },

    invoiceByStatusTotals () {
      const status = this.invoiceByStatus
      const totals = {
        draft: 0,
        open: 0,
        paid: 0,
        pastDue: 0,
        void: 0,
        uncollectible: 0
      }
      status.draft.forEach((item) => (totals.draft += +item.total))
      status.open.forEach((item) => (totals.open += +item.total))
      status.paid.forEach((item) => (totals.paid += +item.total))
      status.pastDue.forEach((item) => (totals.pastDue += +item.total))
      status.void.forEach((item) => (totals.void += +item.total))
      status.uncollectible.forEach((item) => (totals.uncollectible += +item.total))
      return totals
    }

  },

  actions: {
    resetInvoice () {
      this.invoice = cloneDeep(invoiceRef)
    },

    fetchInvoice (uid) {
      return cloneDeep(this.invoices.get(uid))
    },

    fetchInvoicesForClient (contactUid) {
      const invoices = Array.from(this.invoices.values())
      return invoices.filter((doc) => doc.client.uid === contactUid)
    },

    fetchInvoices (docID) {
      const collectionReference = collection(doc(firebaseDb, 'companies', docID), 'invoices')
      const unsubscribe = onSnapshot(collectionReference, (snapshot) => {

        snapshot.docChanges().forEach(async (change) => {
          const doc = change.doc.data()
          const uid = doc.uid
          const company_uid = doc.company_uid

          if (doc.status === 'open' && this.isPastDue(doc.created_at, doc.days_until_due)) {
            await setFirebaseSubcollectionDocument(
                'companies',
                `${company_uid}`,
                'invoices',
                `${uid}`,
                {'status': 'past due'}
            )
          }


          if (change.type === 'added' && !this.invoices.has(uid)) {
            this.invoices.set(uid, doc)
          }

          if (change.type === 'modified' && this.invoices.has(uid)) {
            if (this.invoice.uid === uid) {
              this.invoice = doc
            }
            this.invoices.set(uid, doc)
          }

          if (change.type === 'removed' && this.invoices.has(uid)) {
            this.invoices.delete(uid)
          }
        })
      })

      this.listeners.push(unsubscribe)
    },

    isPastDue(created_at, days_until_due) {
      let currentDate = new Date()
      let createdAt = new Date(created_at)
      let dueDate = new Date()
      dueDate.setDate(createdAt.getDate() + days_until_due)
      return currentDate > dueDate
    },

    handleNewInvoice (company, invoiceUid) {
      const globalStore = useGlobalStore()
      const { reference, plan } = company
      const period_count = reference?.invoice?.period_count

      if (plan.type === 'free' || (plan.type === 'pro' && period_count >= 10)) {
        globalStore.openDialog(
          'warning',
          'Upgrade to Unlock Invoicing',
          'Unlock secure invoicing and get paid faster by upgrading your plan.',
          { name: 'View Plans', action: () => this.router.push('/billing') }
        )
      } else {
        this.router.push({ name: 'Invoice Editor', params: { uid: `${invoiceUid || ''}` } })
      }
    },

    async saveInvoice (user, company, invoice, action) {
      const globalStore = useGlobalStore()

      try {
        await setFirebaseSubcollectionDocument(
            'companies',
            `${user.company_uid}`,
            'invoices',
            `${invoice.uid}`,
            invoice)

        if (action === 'create') {
          await setFirebaseDocument(
            'companies',
            `${invoice.company_uid}`,
            {
              reference: {
                invoice: {
                  number: increment(1),
                  period_count: increment(1)
                }
              }
            }
          )
        }

        globalStore.openNotify(
          'success',
          'Success',
          'Your invoice has been saved.'
        )

        // reset invoice
        this.invoices.set(invoice.uid, invoice)
        this.invoice = cloneDeep(invoiceRef)
        return { message: 'success', success: true, invoice }
      } catch (error) {
        globalStore.openNotify(
          'error',
          'Error',
          'There was an error saving the invoice.')
        return { success: false, message: error }
      }
    },

    async createStripeInvoice (user, company, invoice, action) {
      try {
        const clientStore = useClientStore()
        const client_stripe = await clientStore.assignClientStripeData(invoice.client.uid)
        if (client_stripe.id) invoice.client.stripe = client_stripe
        const callable = httpsCallable(functions, 'stripeCreateInvoice')
        await callable({ invoice, company, action })
      } catch (e) {
        throw new Error('error creating invoice: ' + e)
      }
    },

    async handleCreateConnectAccount (company) {
      const globalStore = useGlobalStore()
      if (company.plan.type === 'free') {
        globalStore.openDialog(
          'warning',
          'Upgrade to Unlock Invoicing',
          'Unlock secure invoicing and get paid faster by upgrading your plan.',
          { name: 'View Plans', action: () => this.router.push('/billing') }
        )
      } else {
        const inProgress = company.plan.stripe_connect.account_uid && !company.plan.stripe_connect.charges_enabled
        let type = inProgress ? 'warning' : 'success'
        let title = inProgress ? 'Stripe Account Incomplete' : 'Connect Stripe Account'
        let text = 'TradeBox uses Stripe to get you paid quickly and keep your personal and payment information secure. Thousands of companies around the world trust Stripe to process payments for their users. '
        text += inProgress
          ? 'Complete your Stripe account to get paid with TradeBox'
          : 'Set up a Stripe account to get' +
          ' paid with TradeBox'
        let button = inProgress ? 'Finish Setup' : 'Connect Stripe'
        globalStore.openDialog(
            type,
            title,
            text,
            {
              name: button,
              action: () => this.handleConnectAccount(company)
            }
        )
      }
    },

    async handleConnectAccount (company) {
      const globalStore = useGlobalStore()
      const companyStore = useCompanyStore()
      try {
        globalStore.isPending = true
        await companyStore.authorizeStripeConnect(company)
      } catch (e) {
        console.log('error', e)
      } finally {
        globalStore.isPending = false
      }

    },

    unsubscribe () {
      this.listeners.forEach(fn => fn())
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInvoiceStore, import.meta.hot))
}
