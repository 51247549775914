<script setup>
import {useRemToPx} from '@/utils'
import useScreen from '@/composables/useScreen.js'
import {usePlatform} from '@/composables/usePlatform.js'
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: ''
  },
  cancelButton: {
    type: String,
    default: 'Cancel'
  },
  confirmButton: {
    type: String,
    default: 'Save'
  },
  destructive: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }

})
const emit = defineEmits(['close', 'confirm'])

const { viewport } = useScreen()

</script>

<template>
  <TransitionRoot
    :show="props.show"
    as="template"
  >
    <Dialog
      v-slot="{open}"
      as="div"
      class="relative z-20"
      @close="open = false"
    >
      <!-- Background overlay -->
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <!-- Dialog -->
      <div class="fixed inset-0 overflow-y-auto z-50">
        <div
          :class="usePlatform.isCapacitor ? 'safe-padding': null"
          class="flex min-h-full justify-center p-4 mx-4 text-center items-center sm:p-0"
        >
          <TransitionChild
            as="div"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <!-- Main content -->
            <DialogPanel
              :class="['relative transform overflow-hidden rounded-lg bg-white  text-left shadow-xl transition-all  sm:w-full sm:max-w-lg  z-50 dark:border']"
            >
              <div
                :style="{ 'max-height': `${viewport.viewHeight - useRemToPx(2) - (usePlatform.isCapacitor && usePlatform.device === 'apple' ? 36 : 0)}px` }"
                class="overflow-y-auto h-fit"
              >
                <div class="sm:pb-0 p-4 sm:p-6">
                  <!-- Heading -->
                  <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle
                      as="h3"
                      class="text-lg font-medium leading-6 text-gray-900"
                    >
                      {{ props.title }}
                    </DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        {{ props.text }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="overflow-y-auto">
                  <!-- content slot -->
                  <slot />
                </div>
                <!-- actions -->
                <div
                  :class="[
                    props.confirmButton ? 'grid-cols-2' : 'grid-cols-1',
                    'grid grid-flow-row-dense gap-3 sticky bottom-0 bg-white p-4'
                  ]"
                >
                  <BaseButton
                    ref="cancelButtonRef"
                    class="flex justify-center"
                    outlined
                    @click="emit('close')"
                  >
                    {{ props.cancelButton }}
                  </BaseButton>

                  <BaseButton
                    v-if="props.confirmButton"
                    :destructive="props.destructive"
                    class="flex justify-center"
                    type="button"
                    :disabled="disabled"
                    :loading="loading"
                    @click="emit('confirm')"
                  >
                    {{ props.confirmButton }}
                  </BaseButton>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
