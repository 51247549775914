<script setup>
import {ref} from 'vue'
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/vue/20/solid'
import {Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions} from '@headlessui/vue'

const props = defineProps({
  modelValue: {
    type: [Number, String, Object, Array],
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  options: {
    type: Array,
    default: () => []
  },
  position: {
    type: String,
    default: 'bottom'
  }
})

const emit = defineEmits(['update:modelValue', 'change'])

const isSelected = ref(props.modelValue ?? '')

function updateInput () {
  emit('update:modelValue', isSelected.value)
  emit('change', isSelected.value)
}
</script>

<template>
  <Listbox
    v-model="isSelected"
    as="div"
  >
    <!-- Label -->
    <ListboxLabel
      v-if="label"
      class="block text-sm/6 font-medium text-gray-900 dark:text-white mb-2"
    >
      {{ label }}
    </ListboxLabel>

    <!-- Select Container -->
    <div class="relative">
      <!-- Select -->
      <ListboxButton
        class="relative h-11 w-full cursor-default rounded-md bg-white dark:bg-white/5 border border-gray-900/20 dark:border-white/10 py-2 pl-3 pr-8 text-left shadow-sm focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 sm:text-sm flex items-center "
        style="max-height: 39px"
      >
        <span class="block truncate capitalize text-gray-900 dark:text-white">
          {{ isSelected?.label || isSelected?.name || isSelected }}
        </span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <ChevronUpDownIcon
            aria-hidden="true"
            class="h-5 w-5 text-gray-400"
          />
        </span>
      </ListboxButton>

      <!-- Option dropdown container -->
      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <!-- Options -->
        <ListboxOptions
          :class="props.position === 'top' ? ' -top-60': null "
          class="absolute z-10 mt-1 max-h-60 w-full right-0 overflow-auto rounded-md bg-white dark:bg-gray-950 py-1 text-base shadow-lg ring-1 ring-gray-900/10 dark:ring-white/20 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="option in options"
            :key="option.label ?? option"
            v-slot="{ active, selected }"
            :value="option"
            as="template"
            @click="updateInput"
          >
            <li
              :class="[
                selected || isSelected === option || isSelected === option.label || isSelected === option.name ? 'bg-blue-600 text-white' : 'hover:bg-gray-50 dark:hover:bg-white/5',
                'relative cursor-default select-none py-2 pl-3 pr-9 capitalize '
              ]"
            >
              <!-- Option  -->
              <span
                :class="[
                  selected || isSelected === option.label || isSelected === option|| isSelected === option.name ? 'font-semibold' : 'font-normal',
                  'block truncate'
                ]"
              >
                {{ option.label || option }}
              </span>

              <!-- Check if selected -->
              <span
                v-if=" selected || isSelected === option.label || isSelected === option || isSelected === option.name "
                :class="[
                  active || isSelected === option.label || isSelected === option || isSelected === option.name ? 'text-white' : 'text-blue-600',
                  'absolute inset-y-0 right-0 flex items-center pr-4'
                ]"
              >
                <CheckIcon
                  aria-hidden="true"
                  class="h-5 w-5"
                />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
