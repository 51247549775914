<script setup>
import {ref} from 'vue'
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue'
import useScreen from '@/composables/useScreen.js'
import {usePlatform} from '@/composables/usePlatform.js'
import {useIntersectionObserver} from '@vueuse/core'

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  cancelButton: {
    type: String,
    default: 'Cancel'
  },
  confirmButton: {
    type: String,
    default: 'Save'
  },
  deleteButton: {
    type: String,
    default: 'Delete'
  },
  loading: {
    type: Boolean,
    default: false
  },
  showDeleteButton: {
    type: Boolean,
    default: false
  },
  showConfirm: {
    type: Boolean,
    default: true
  },
  width: {
    type: String,
    default: 'w-screen max-w-3xl'
  }
})

const emit = defineEmits(['confirm', 'close', 'delete'])

const {
  screen,
  viewport
} = useScreen()

const focus = ref(null)
const target = ref(null)
const targetIsVisible = ref(true)

useIntersectionObserver(
    target,
    ([{isIntersecting}]) => {
      targetIsVisible.value = isIntersecting
    }
)

</script>

<template>
  <TransitionRoot
    :show="props.show"
    as="template"
  >
    <Dialog
      :initial-focus="focus"
      as="div"
      class="relative z-60 min-h-fit"
      @close="emit('close')"
    >
      <!-- Background overlay -->
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity z-10" />
      </TransitionChild>

      <!-- Dialog container -->
      <div class="fixed inset-0 z-20 overflow-hidden">
        <div class="flex min-h-full items-start justify-center text-center md:items-center">
          <TransitionChild
            as="div"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enter-to="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 md:scale-100"
            leave-to="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <!-- Dialog -->
            <DialogPanel
              :class="[width, usePlatform.isCapacitor ? 'safe-padding-top pb-20' : null]"
              class="relative transform md:pb-0 md:rounded-lg overflow-hidden bg-white dark:bg-gray-950 text-left shadow-xl transition-all dark:md:border border-gray-900/10 dark:border-white/20"
            >
              <!-- solve for init focus -->
              <input
                ref="focus"
                class="hidden"
              >

              <div
                :style="{ 'max-height': `${viewport.viewHeight - (usePlatform.isCapacitor && usePlatform.device === 'apple' ? 12: screen.gt.xs ? 64 : 0)}px` }"
                class="overflow-y-auto h-screen md:h-fit md:pb-0"
              >
                <!-- mobile only - sticky header actions -->
                <div
                  class="px-4 sticky top-0 bg-white dark:bg-gray-950 md:hidden z-50 border-b border-gray-900/10 dark:border-white/20"
                >
                  <div class="flex items-center justify-between py-2 relative">
                    <base-button-secondary
                      class="z-10"
                      @click="emit('close')"
                    >
                      {{ props.cancelButton }}
                    </base-button-secondary>

                    <!-- title -->
                    <div
                      :class="[targetIsVisible ? 'opacity-0': 'opacity-100', 'lg:hidden text-center text-gray-900 dark:text-white text-base font-semibold transition-opacity duration-300 ease-in-out absolute w-full z-0']"
                    >
                      <div class="max-w-[10rem] sm:max-w-lg mx-auto">
                        {{ props.title }}
                      </div>
                    </div>

                    <base-button-primary
                      v-if="props.showConfirm"
                      :disabled="props.disabled"
                      :loading="props.loading"
                      class="z-10"
                      @click="emit('confirm')"
                    >
                      {{ confirmButton }}
                    </base-button-primary>
                  </div>
                </div>

                <!-- Headline -->
                <div ref="target" />

                <div
                  class="mb-6 md:mb-0 px-4 py-6 md:p-6 border-b border-gray-900/10 dark:border-white/20 md:sticky md:top-0 bg-white dark:bg-gray-950 z-10 w-full"
                >
                  <h3
                    v-if="props.title"
                    class="text-base/7 font-semibold text-gray-900 dark:text-white"
                  >
                    {{ props.title }}
                  </h3>
                  <p
                    v-if="props.text"
                    class="mt-1 max-w-2xl text-sm leading-6 text-gray-600 dark:text-gray-400"
                  >
                    {{ props.text }}
                  </p>
                </div>

                <!-- Content slot -->
                <div>
                  <slot />
                </div>

                <!-- Action buttons -->
                <div
                  v-if="usePlatform.isCapacitor || (props.showDeleteButton || screen.gt.xs)"
                  :class="[usePlatform.isCapacitor ? 'safe-padding-bottom mb-8' : null, screen.gt.xs ? 'border-t border-gray-900/10 dark:border-white/20': null]"
                  class="flex justify-between space-x-4 p-4 md:px-6 sm:sticky sm:bottom-0 bg-white dark:bg-gray-950"
                >
                  <div class="w-full">
                    <base-button-secondary
                      v-if="props.showDeleteButton"
                      class="w-full md:w-fit"
                      destructive
                      @click="emit('delete')"
                    >
                      <span class="capitalize">

                        {{ props.deleteButton }}
                      </span>
                    </base-button-secondary>
                  </div>

                  <div class="hidden md:flex space-x-4">
                    <base-button-secondary
                      @click="emit('close')"
                    >
                      {{ props.cancelButton }}
                    </base-button-secondary>

                    <base-button-primary
                      v-if="props.showConfirm"
                      :disabled="props.disabled"
                      :loading="props.loading"
                      @click="emit('confirm')"
                    >
                      {{ props.confirmButton }}
                    </base-button-primary>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
