<script setup>
import {computed, defineAsyncComponent, onMounted, watch} from 'vue'
import {useHead} from 'unhead'
import {useDark, useStorage, useToggle} from '@vueuse/core'
import {Keyboard, KeyboardStyle} from '@capacitor/keyboard'
import {useAuthStore} from '@/stores/useAuthStore'
import {StatusBar, Style} from '@capacitor/status-bar'
import {usePlatform} from '@/composables/usePlatform.js'
import GlobalNotify from '@/components/GlobalNotify.vue'
import GlobalDialog from '@/components/GlobalDialog.vue'
import {useIAPStore} from '@/stores/useIAPStore.js'
import {useUserStore} from '@/stores/useUserStore.js'
import {useRoute} from 'vue-router'
import {useCompanyStore} from '@/stores/useCompanyStore.js'

const AiChatBot = defineAsyncComponent(() =>
  import('@/components/AiChatBot.vue')
)
const route = useRoute()
const isDark = useDark()
useToggle(isDark)

const useAi = useStorage('useAi', true)

const useIAP = useIAPStore()
const authStore = useAuthStore()
const userStore = useUserStore()
const companyStore = useCompanyStore()

const user = computed(() => userStore.user)
const company = computed(() => companyStore.company)

const showAI = computed(
  () => {
    return useAi.value && user &&
           (route?.meta?.requiresAuth && !['Estimate Editor', 'Invoice Editor'].includes(route.name))
  })

onMounted(() => {
  authStore.handleAuthStateChange()
  if (usePlatform.isCapacitor) {
    setMobileAccessories()
    setTimeout(() => {
      useIAP.initProducts()
    }, 5000)
  }
})

watch(
  () => isDark.value,
  (val) => {
    if (usePlatform.isCapacitor) {
      setMobileAccessories()
    }
  }
)

/*
watch(
  () => route.path,
  (path) => {
    if (user.value.uid && company.value.uid && ['/auth', '/sign-in', '/sign-up'].includes(path)) {
      router.push('/')
    }
  }
)
*/

watch(
    () => company.value.uid,
    (path) => {
      if (user.value.uid && company.value.uid && ['/auth', '/sign-in', '/sign-up'].includes(path)) {
        router.push('/')
      }
    }
)

useHead({
  title: 'TradeBox: The Ultimate App for Contractors & Tradesmen',
  meta: [
    { name: 'theme-color', content: isDark ? '#030712' : '#fff' }
  ]
})

async function setMobileAccessories () {
  const statusBarStyle = isDark.value ? Style.Dark : Style.Light
  const keyboardStyle = isDark.value ? KeyboardStyle.Dark : KeyboardStyle.Light

  await Keyboard.setAccessoryBarVisible({ isVisible: false })
  await Keyboard.setScroll({ isDisabled: true })
  await StatusBar.setStyle({ style: statusBarStyle })
  await Keyboard.setStyle({ style: keyboardStyle })
}
</script>

<template>
  <div class="min-h-screen">
    <!-- Global components -->
    <global-dialog />
    <global-notify />
    <ai-chat-bot v-show="showAI" />

    <!-- Main Content View -->
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>
