<script setup>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {CheckCircleIcon, ExclamationCircleIcon} from '@heroicons/vue/24/outline'
import {usePlatform} from '@/composables/usePlatform.js'
import {useGlobalStore} from '@/stores/useGlobalStore.js'
import {ref} from 'vue'

const useGlobal = useGlobalStore()
const focus = ref(null)
</script>

<template>
  <TransitionRoot
    :show="useGlobal.dialog.show"
    as="template"
  >
    <Dialog
      v-slot="{open}"
      as="div"
      class="relative z-50"
      :initial-focus="focus"
      @close="open = false"
    >
      <!-- Background overlay -->
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <!-- Dialog -->
      <div class="fixed inset-0 overflow-y-auto z-50">
        <div
          :class="usePlatform.isCapacitor ? 'safe-padding': null"
          class="flex min-h-full justify-center p-4 mx-4 text-center items-center sm:p-0"
        >
          <TransitionChild
            as="div"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <!-- Main content -->
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-950 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-[calc(100vw_-_4rem)] sm:w-full sm:max-w-lg sm:p-6 z-50 border border-gray-900/10 dark:border-white/10"
            >
              <!-- input -->
              <input
                ref="focus"
                class="hidden"
              >
              <div>
                <!-- Success icon -->
                <div
                  v-if="useGlobal.dialog.type === 'success'"
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-600/20"
                >
                  <CheckCircleIcon
                    aria-hidden="true"
                    class="h-8 w-8 text-green-600"
                  />
                </div>

                <!-- Destructive icon -->
                <div
                  v-if="useGlobal.dialog.type === 'destructive'"
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-600/20"
                >
                  <ExclamationCircleIcon
                    aria-hidden="true"
                    class="h-8 w-8 text-red-500"
                  />
                </div>

                <div
                  v-if="useGlobal.dialog.type === 'warning'"
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-600/20"
                >
                  <ExclamationCircleIcon
                    aria-hidden="true"
                    class="h-8 w-8 text-yellow-500"
                  />
                </div>

                <!-- Heading -->
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    class="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    {{ useGlobal.dialog.title }}
                  </DialogTitle>

                  <div class="mt-2">
                    <p class="text-sm text-gray-600 dark:text-gray-400">
                      {{ useGlobal.dialog.text }}
                    </p>
                  </div>
                </div>
              </div>

              <div
                :class="[
                  useGlobal?.dialog.action?.name ? 'grid-cols-2' : 'grid-cols-1',
                  'mt-6 grid grid-flow-row-dense gap-3'
                ]"
              >
                <base-button-secondary
                  ref="cancelButtonRef"
                  class="flex justify-center"
                  @click="useGlobal.closeDialog()"
                >
                  {{ useGlobal.dialog.dismiss ? useGlobal.dialog.dismiss : 'Close' }}
                </base-button-secondary>

                <base-button-primary
                  v-if="useGlobal?.dialog.action?.name"
                  :loading="useGlobal.isPending"
                  :destructive="useGlobal.dialog.type === 'destructive'"
                  @click="useGlobal.confirmDialog()"
                >
                  {{ useGlobal.dialog.action.name }}
                </base-button-primary>
              </div>

              <div
                v-if="useGlobal.dialog.subtext"
                class="mt-4"
              >
                <p class="text-xs text-gray-600 dark:text-gray-400 text-center">
                  {{ useGlobal.dialog.subtext }}
                </p>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
