<script setup>
import { computed } from 'vue'

const props = defineProps({
  destructive: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: 'Edit'
  }
})

const general = 'inline-flex items-center rounded-full text-sm font-medium leading-5'
const isDestructive = 'text-red-500 hover:bg-red-50 dark:hover:bg-white/20'
const regular = 'text-blue-600 hover:bg-gray-900/10 dark:hover:bg-white/20 p-1'

const styles = computed(() => [props.destructive ? isDestructive : regular, general])
</script>

<template>
  <button
    :class="styles"
    v-bind="$attrs"
  >
    <span v-if="!$slots.default"> {{ label }}</span>

    <slot />
  </button>
</template>
