// useRoute
import {createRouter, createWebHistory} from 'vue-router'
import routes from '@/router/routes.js'
import {firebaseAuth} from '@/apis/index.js'
import {useUserStore} from '@/stores/useUserStore.js'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || new Promise((resolve) => {
      setTimeout(() => resolve({ top: 0 }), 300)
    })
  }
})

router.beforeEach(async (to, from, next) => {
  const userUid = useUserStore().user.uid
  const isAuthorized = !!firebaseAuth.currentUser && !!userUid
  if (!to?.meta?.requiresAuth && (to.path === '/auth' || to.path === '/sign-up' || to.path === '/sign-in') &&
    isAuthorized) {
    next({ name: 'Dashboard' })
  } else if (to?.meta?.requiresAuth && !isAuthorized) {
    next({ name: 'Sign Up' })
  } else {
    next()
  }
})

export default router
