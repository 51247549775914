const routes = [// Unrestricted
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/pages/Auth.vue')
  }, {
    path: '/sign-in',
    name: 'Sign In',
    component: () => import('@/pages/Auth.vue')
  }, {
    path: '/sign-up',
    name: 'Sign Up',
    component: () => import('@/pages/Auth.vue')
  }, {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/pages/settings/Privacy.vue')
  }, {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/pages/settings/Terms.vue')
  }, {
    path: '/auth-success',
    name: 'Auth Success',
    component: () => import('@/pages/CalendarSuccess.vue')
  }, {
    path: '/stripe-connect',
    name: 'Stripe Connect',
    component: () => import('@/pages/StripeConnect.vue')
  }, {
    path: '/c2-est',
    name: 'Client Digital Estimate',
    props: true,
    component: () => import('@/pages/ClientDigitalEstimate.vue')
  }, {
    path: '/c2-inv',
    name: 'Client Digital Invoice',
    props: true,
    component: () => import('@/pages/ClientDigitalInvoice.vue')
  },

  // restricted no layout
  {
    path: '/estimate-editor/:uid?',
    name: 'Estimate Editor',
    meta: {
      requiresAuth: true
    },
    props: true,
    component: () => import('@/pages/EstimateEditor.vue')
  }, {
    path: '/estimate-overview/:uid?',
    name: 'Estimate Overview',
    meta: {
      requiresAuth: true
    },
    props: true,
    component: () => import('@/pages/EstimateOverviewEditor.vue')
  }, {
    path: '/invoice-editor/:uid?',
    name: 'Invoice Editor',
    meta: {
      requiresAuth: true
    },
    props: true,
    component: () => import('@/pages/InvoiceEditor.vue')
  }, {
    path: '/billing',
    name: 'Plans & Billing',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/pages/settings/Billing.vue')
  }, {
    path: '/connect-calendar',
    name: 'Connect Calendar',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/pages/CalendarAuth.vue')
  }, {
    path: '/stripe-success',
    name: 'Stripe Success',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/pages/StripeSuccess.vue')
  }, /* Restricted - Main Sidebar Layout */
  {
    path: '/',
    component: () => import('@/layouts/Default.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'leads',
        name: 'Leads',
        component: () => import('@/pages/Leads.vue')
      }, {
        path: 'more',
        name: 'More',
        component: () => import('@/pages/Dashboard.vue')
      }, {
        path: '',
        name: 'Dashboard',
        component: () => import('@/pages/Dashboard.vue')
      }, {
        path: 'clients',
        name: 'Clients',
        component: () => import('@/pages/Clients.vue')
      }, {
        path: 'calendar',
        name: 'Calendar',
        component: () => import('@/pages/Calendar.vue')
      }, {
        path: 'invoices',
        name: 'Invoices',
        component: () => import('@/pages/Invoices.vue')
      }, {
        path: 'estimates',
        name: 'Estimates',
        component: () => import('@/pages/Estimates.vue')
      }, {
        path: 'job-market',
        name: 'Job Market',
        component: () => import('@/pages/JobMarket.vue')
      }, {
        path: 'work-orders',
        name: 'Work Orders',
        component: () => import('@/pages/Estimates.vue')
      }, {
        path: '/photos',
        name: 'Photos',
        props: true,
        component: () => import('@/pages/Photos.vue')
      }, {
        path: 'lead/:uid',
        name: 'Lead Details',
        props: true,
        component: () => import('@/pages/LeadDetails.vue')
      }, {
        path: 'client/:uid',
        name: 'Client Details',
        props: true,
        component: () => import('@/pages/ClientDetails.vue')
      }, {
        path: 'invoice/details/:uid',
        name: 'Invoice Details',
        props: true,
        component: () => import('@/pages/InvoiceDetails.vue')
      }, {
        path: 'estimate/details/:uid',
        name: 'Estimate Details',
        props: true,
        component: () => import('@/pages/EstimateDetails.vue')
      }, {
        path: 'job-market/settings',
        name: 'Job Market Settings',
        component: () => import('@/pages/settings/JobMarket.vue')
      }, {
        path: 'job-market/:uid',
        name: 'Company Details',
        props: true,
        component: () => import('@/pages/CompanyProfileDetails.vue')
      }
    ]
  },

  // Restricted - Settings Layout
  {
    path: '/settings',
    component: () => import('@/layouts/Settings.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'profile',
        meta: {
          tabs: [
            {
              name: 'Company Profile',
              to: 'company'
            }, {
              name: 'User Profile',
              to: 'user'
            }, {
              name: 'Account',
              to: 'account'
            }
          ]
        },
        children: [
          {
            path: 'company',
            name: 'Company Profile',
            component: () => import('@/pages/settings/ProfileCompany.vue')
          }, {
            path: 'user',
            name: 'User Profile',
            component: () => import('@/pages/settings/ProfileUser.vue')
          }, {
            path: 'Account',
            name: 'Account',
            component: () => import('@/pages/settings/Account.vue')
          }
        ]
      }, {
        path: 'estimating',
        children: [
          {
            path: 'preferences',
            name: 'Estimate Preferences',
            component: () => import('@/pages/settings/EstimatePreferences.vue')
          }
        ]
      }, {
        path: 'items',
        meta: {
          tabs: [
            {
              name: 'Line Items',
              to: 'line-items'
            }, {
              name: 'Product Packs',
              to: 'product-packs'
            }, {
              name: 'Taxes',
              to: 'tax'
            }
          ]
        },
        children: [
          {
            path: 'line-items',
            name: 'Line Items',
            component: () => import('@/pages/settings/LineItems.vue')
          }, {
            path: 'product-packs',
            name: 'Product Packs',
            component: () => import('@/pages/settings/ProductPacks.vue')
          }, {
            path: 'tax',
            name: 'Tax',
            component: () => import('@/pages/settings/Tax.vue')
          }
        ]
      }, {
        path: 'documents',
        children: [
          {
            path: 'user-documents',
            name: 'User Document ',
            component: () => import('@/pages/settings/DocumentCreate.vue')
          }
        ]
      }, {
        path: 'invoicing',
        children: [
          {
            path: 'preferences',
            name: 'Invoice Preferences',
            component: () => import('@/pages/settings/InvoicePreferences.vue')
          }
        ]
      }, /* {
       path: 'work-orders',
       meta: {
       tabs: [
       { name: 'Line Items', to: 'line-items' },
       { name: 'Products', to: 'products' },
       { name: 'Tax', to: 'tax' }
       ]
       },
       children: [
       {
       path: 'line-items',
       name: 'Line Items',
       component: () => import('@/pages/settings/LineItems.vue')
       },
       {
       path: 'products',
       name: 'Products',
       component: () => import('@/pages/settings/Products.vue')
       },
       {
       path: 'tax',
       name: 'Tax',
       component: () => import('@/pages/settings/Tax.vue')
       }
       ]
       },*/{
        path: 'review',
        children: [
          {
            path: 'preferences',
            name: 'Review Preferences',
            component: () => import('@/pages/settings/ReviewPreferences.vue')
          }
        ]
      },
      {
        path: 'communication',
        meta: {
          tabs: [
            {
              name: 'Email Templates',
              to: 'email-templates'
            }, {
              name: 'Links',
              to: 'links'
            } // { name: 'Preferences', to: 'preferences' }
          ]
        },
        children: [
          {
            path: 'email-templates',
            name: 'Email Templates',
            component: () => import('@/pages/settings/EmailTemplates.vue')
          }, {
            path: 'links',
            name: 'Links',
            component: () => import('@/pages/settings/Links.vue')
          }, {
            path: 'preferences',
            name: 'Communication Preferences',
            component: () => import('@/pages/settings/Preferences.vue')
          }
        ]
      }, {
        path: 'general',
        name: 'Application Preferences',
        component: () => import('@/pages/settings/GeneralPreferences.vue')
      },

      {
        path: 'support',
        name: 'Support',
        component: () => import('@/pages/Support.vue')
      }, {
        path: 'support/ticket',
        name: 'Support Ticket',
        component: () => import('@/pages/SupportTicketCreate.vue')
      }, {
        path: 'support/support-ticket/:uid',
        name: 'My Support Ticket',
        props: true,
        component: () => import('@/pages/SupportTicket.vue')
      }
    ]
  },

  // Unrestricted - Page 404
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/pages/404.vue')
  }
]

export default routes
