<script setup>
import { computed } from 'vue'
import { Device } from '@capacitor/device'
import { usePlatform } from '@/composables/usePlatform.js'

const props = defineProps({
  type: {
    type: String,
    default: 'email'
  },
  label: {
    type: String,
    default: ''
  },
  phone: {
    type: [String, Number],
    default: ''
  },
  email: {
    type: String,
    default: ''
  }
})

const language = async () => {
  if (usePlatform.isCapacitor) {
    return await Device.getLanguageCode()
  } else {
    return navigator.language
  }

}

const link = computed(() => {
  switch (props.type) {
    case 'email':
      return props.email ? `mailto:${props.email}` : null
    case 'phone':
      return props.phone ? `tel:${getPhoneLink()}` : null
    case 'text':
      return props.phone ? `sms:${getPhoneLink()}` : null
    default:
      return null
  }
})

function getPhoneLink () {
  if (!props.phone) return null

  let countryCode = '+1' // Default to US country code

  switch (language()) {
    case 'en-US':
      countryCode = '+1' // US country code
      break
    case 'en-CA':
      countryCode = '+1' // Canada country code
      break
    case 'es-MX':
      countryCode = '+52' // Mexico country code
      break
    case 'en-AU':
      countryCode = '+61' // Australia country code
      break
    case 'en-NZ':
      countryCode = '+64' // New Zealand country code
      break
  }

  return `${countryCode}${props.phone || ''}`
}
</script>

<template>
  <a :aria-label="props.label" :href="link">
    <span v-if="!$slots.default">{{ props.label }}</span>
    <slot />
  </a>
</template>

