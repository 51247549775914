import {acceptHMRUpdate, defineStore} from 'pinia'
import {collection, doc, increment, onSnapshot} from 'firebase/firestore'
import {firebaseDb, setFirebaseDocument, setFirebaseSubcollectionDocument} from '@/apis'
import {estimate as estimateRef} from '@/utils/index.js'
import {useGlobalStore} from '@/stores/useGlobalStore.js'
import {cloneDeep} from 'lodash'
import {useStorage} from '@vueuse/core';

const hideLineItemOnboarding = useStorage('hideLineItemOnboarding', false)
export const useEstimateStore = defineStore('estimate', {
  state: () => ({
    estimates: new Map(),
    sortBy: 'createdAt',
    sortByStatus: 'all',
    listeners: [],
    duplicateEstimate: null,
    estimate: cloneDeep(estimateRef),
    estimateClone: cloneDeep(estimateRef),
    isOnboardingDialog: false,
    statDays: 7
  }),

  getters: {
    sortedEstimates(state) {
      const estimates = Array.from(state.estimates.values())
      if (state.sortBy === 'name') {
        return estimates.sort((a, b) => {
          if (a.client.name > b.client.name) return -1
          if (a.client.name < b.client.name) {
            return 1
          } else {
            return 0
          }
        })
      }
      if (state.sortBy === 'createdAt') {
        return estimates.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      }
    },

    recentEstimates() {
      const groupedEstimates = []

      this.sortedEstimates.forEach(estimate => {
        const dateNow = new Date()
        const createdDate = new Date(estimate.created_at)
        const diffTime = Math.abs(dateNow - createdDate)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

        if (diffDays <= 7) {
          const date = createdDate.toLocaleDateString('en-US', {
            weekday: 'long',
            month: 'long',
            day: 'numeric'
          })

          // check if there is an existing group for this date
          const existingGroup = groupedEstimates.find(group => group.date === date)

          // if there is, add the estimate to the existing group if it has not reached the maximum number of estimates
          if (existingGroup) {
            existingGroup.items.push(estimate)
          } else {
            // otherwise, create a new group for this date and add the estimate
            groupedEstimates.push({
              date,
              dateTime: estimate.created_at,
              items: [estimate]
            })
          }
        }
      })
      return groupedEstimates
    },

    estimateByStatus(state) {
      const dateNow = new Date()
      const filteredEstimates = this.sortedEstimates.filter((doc) => {
        const createdDate = new Date(doc.created_at)
        const diffTime = Math.abs(dateNow - createdDate)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return diffDays <= state.statDays
      })

      return {
        all: filteredEstimates,
        draft: filteredEstimates.filter((doc) => doc.status === 'draft'),
        open: filteredEstimates.filter((doc) => doc.status === 'open'),
        approved: filteredEstimates.filter((doc) => doc.status === 'approved'),
        canceled: filteredEstimates.filter((doc) => doc.status === 'canceled')
      }
    },

    estimateByStatusTotals() {
      const status = this.estimateByStatus
      const totals = {
        draft: 0,
        open: 0,
        approved: 0,
        canceled: 0
      }
      status.draft.forEach((item) => (totals.draft += +item.total))
      status.open.forEach((item) => (totals.open += +item.total))
      status.approved.forEach((item) => (totals.approved += +item.total))
      status.canceled.forEach((item) => (totals.canceled += +item.total))
      return totals
    }

  },

  actions: {
    /**
     * reset the estimate
     */
    resetEstimate() {
      this.estimate = cloneDeep(estimateRef)
    },

    /**
     *  fetch a specific estimate by uid
     * @param uid
     * @return {Object} estimate
     */
    fetchEstimate(uid) {
      return cloneDeep(this.estimates.get(uid)) || cloneDeep(estimateRef)
    },

    /**
     *
     * @param client_uid
     * @return {Array} A specific client estimates
     */
    fetchEstimatesForClient(client_uid) {
      const estimates = Array.from(this.estimates.values())
      return estimates.filter((doc) => doc.client.uid === client_uid)
    },

    /**
     * Fetch Estimates
     * @param companyID
     */
    fetchEstimates(companyID) {
      const collectionReference = collection(doc(firebaseDb, 'companies', companyID), 'estimates')
      const unsubscribe = onSnapshot(collectionReference, (snapshot) => {

        snapshot.docChanges().forEach((change) => {
          const doc = change.doc.data()
          const uid = doc.uid

          if (change.type === 'added') {
            this.estimates.set(uid, doc)
          }

          if (change.type === 'modified') {
            this.estimates.set(uid, doc)
            if (this.estimate.uid === uid) {
              this.estimate = doc
            }
          }

          if (change.type === 'removed') {
            if (this.estimates.has(uid)) {
              this.estimates.delete(uid)
            }
          }
        })
      })

      this.listeners.push(unsubscribe)
    },

    handleNewEstimate(company) {
      const globalStore = useGlobalStore()
      const {
        plan,
        reference
      } = company
      const period_count = reference?.estimate?.period_count

      if (plan.type === 'free' && period_count >= 5) {
        globalStore.openDialog('warning', 'Upgrade to Unlock Estimating', 'You have reached your monthly estimate limit. Upgrade to access additional estimating capabilities.', {
          name: 'View Plans',
          action: () => this.router.push('/billing')
        })
      } else if (!hideLineItemOnboarding.value) {
        this.isOnboardingDialog = true
      } else {
        this.router.push({name: 'Estimate Editor'})
      }
    },

    /**
     * Save estimate
     * @param user
     * @param company
     * @param data
     * @param action
     * @return {Promise<{success: boolean, message}|{data, success: boolean, message: string}>}
     */
    async saveEstimate(user, company, data, action) {
      const globalStore = useGlobalStore()

      try {
        await setFirebaseSubcollectionDocument('companies', user.company_uid, 'estimates', data.uid, data)

        if (action === 'add') {
          await setFirebaseDocument('companies', data.company_uid, {
            reference: {
              estimate: {
                number: increment(1),
                period_count: increment(1)
              }
            }
          })
        }

        this.estimates.set(data.uid, data)

        globalStore.openNotify('success', 'Success', 'Your estimate has been saved.')
        // reset estimate
        this.resetEstimate()
        return {
          message: 'success',
          success: true,
          data
        }
      } catch (error) {
        globalStore.openNotify('error', 'Error', 'There was an error saving the estimate.')
        return {
          success: false,
          message: error
        }
      }
    },

    /**
     * Unsubscribe from listeners
     */
    unsubscribe() {
      this.listeners.forEach(fn => fn())
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEstimateStore, import.meta.hot))
}
