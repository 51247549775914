<script setup>
import { onMounted, ref, watch } from 'vue'
import { useToggleArrayItem } from '@/utils'
import { cloneDeep } from 'lodash'
import { CheckIcon, ChevronUpDownIcon, PlusIcon } from '@heroicons/vue/20/solid'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue'

const props = defineProps({
  modelValue: {
    type: [String, Array],
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  options: {
    type: Array,
    default: () => []
  },
  multiple: {
    type: Boolean,
    default: false
  },
  position: {
    type: String,
    default: 'bottom'
  }
})

const emit = defineEmits(['update:modelValue'])

const isSelected = ref(null)
const optionsClone = ref([])
const query = ref('')
const newItem = ref('')

onMounted(() => {
  optionsClone.value = cloneDeep(props.options)
  if (typeof props.modelValue === 'string') {
    isSelected.value = props.modelValue
  } else if (typeof props.modelValue === 'object') {
    if (props.modelValue && props.modelValue?.length) {
      isSelected.value = cloneDeep(props.modelValue)
    } else {
      isSelected.value = []
    }
  }
})

watch(() => props.modelValue,
  () => {
    if (props.modelValue) {
      isSelected.value = cloneDeep(props.modelValue)
    }
  }
)

function updateInput (val) {
  if (val) {
    query.value = val
    if (props.multiple) {
      useToggleArrayItem(isSelected.value, val)
    } else {
      isSelected.value = val
    }
    emit('update:modelValue', isSelected.value)
  }
}

function clickedDismiss (val) {
  useToggleArrayItem(isSelected.value, val)
  emit('update:modelValue', isSelected.value)
}

function addNewItem (val) {
  if (val) {
    if (!optionsClone.value.includes(val) && !optionsClone.value.includes(val.toLowerCase())) {
      optionsClone.value.push(val)
    }
    if (!isSelected.value.includes(val) && !isSelected.value.includes(val.toLowerCase())) {
      isSelected.value.push(val)
      emit('update:modelValue', isSelected.value)
      newItem.value = ''
    }
  }
}
</script>
<template>
  <Combobox
    :multiple="multiple"
    as="div"
  >
    <!-- label -->
    <ComboboxLabel v-if="label" class="block text-sm font-medium leading-6 text-gray-900">
      {{ label }}
    </ComboboxLabel>



    <div class="relative">
      <!-- input container -->
      <div class="flex">
        <div class="relative w-full">
          <ComboboxInput
            class="w-full rounded-md border-0 bg-white py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            @change="newItem = $event.target.value"
          />

          <ComboboxButton
            :class="['absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none']"
          >
            <ChevronUpDownIcon aria-hidden="true" class="h-5 w-5 text-gray-400" />
          </ComboboxButton>
        </div>

        <base-button
          class="ml-3"
          outlined
          @click.prevent="addNewItem(newItem)"
        >
          <PlusIcon aria-hidden="true" class="h-5 w-5 text-gray-400 dark:text-gray-900" />
        </base-button>
      </div>

      <!-- selected labels -->
      <div v-if="Array.isArray(isSelected) && isSelected?.length" class="mt-2 absolute">
        <BaseBadge
          v-for="item in isSelected"
          :key="item"
          :label="item"
          class="truncate mr-2"
          dismissible
          @dismiss="clickedDismiss(item)"
        />
      </div>

      <ComboboxOptions
        :class="[props.position === 'top' ? ' -top-44': null , 'absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm']"
      >
        <ComboboxOption
          v-for="option in optionsClone"
          :key="option"
          v-slot="{ selected }"
          :value="option"
          as="template"
          @click="updateInput(option)"
        >
          <li
            :class="[
              selected || isSelected?.includes(option?.label || option?.name || option ) ? 'bg-gray-100 text-blue-600 font-semibold' : ' text-gray-600 font-normal',
              'relative cursor-default select-none py-2 pl-3 pr-9 capitalize'
            ]"
          >
            <!-- label -->
            <span class="block truncate">
              {{ option?.label || option?.name || option }}
            </span>

            <!-- Check if selected -->
            <span
              v-if="selected || isSelected?.includes(option?.label || option?.name || option )"
              :class="[ 'absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600' ]"
            >
              <CheckIcon aria-hidden="true" class="h-5 w-5" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>
