import {acceptHMRUpdate, defineStore} from 'pinia'
import {sendEmailVerification, updateEmail} from 'firebase/auth'
import {cloneDeep, isEqual} from 'lodash'
import {user as userRef} from '@/utils'
import {firebaseAuth, firebaseDb, setFirebaseDocument} from '@/apis'
import {useGlobalStore} from '@/stores/useGlobalStore.js'
import {doc, onSnapshot} from 'firebase/firestore'

export const useUserStore = defineStore('user', {
  state: () => ({
    user: userRef,
    clone: userRef,
    listeners: []
  }),

  getters: {
    userProfileChanged (state) {
      return !isEqual(state.user, state.clone)
    },

    avatar (state) {
      new URL(state.user?.avatar?.url, import.meta.url)
    }
  },

  actions: {

    cloneUser() {
      this.clone = cloneDeep(this.user)
    },

    async fetchUserProfile (docID) {
      const docRef = doc(firebaseDb, 'users', docID)

      return new Promise((resolve, reject) => {
        let timeoutId // Variable to store the timeout ID

        const unsubscribe = onSnapshot(docRef, (docSnapshot) => {

          if (docSnapshot.exists()) {
            clearTimeout(timeoutId) // Clear the timeout if the document exists
            this.user = docSnapshot.data()
            resolve(this.user)
          }
        }, (error) => {
          clearTimeout(timeoutId) // Clear the timeout if an error occurs
          reject(new Error('Error getting document: ' + error))
        })

        // Set a timeout to handle the case when the document doesn't initially exist
        timeoutId = setTimeout(() => {
          unsubscribe() // Unsubscribe from the snapshot listener
          reject(new Error('Timeout: Document not found'))
        }, 5000) // Adjust the timeout value as needed

        // Store the unsubscribe function for later cleanup
        this.listeners.push(() => {
          clearTimeout(timeoutId) // Clear the timeout if the listener is unsubscribed
          unsubscribe() // Unsubscribe from the snapshot listener
        })
      })
    },


    async saveUserProfile (password) {
      const globalStore = useGlobalStore()
      const newEmail = this.clone.email.toLowerCase()
      const docID = this.user.uid

      try {
        // Reauthenticate user if uses password save auth email and user profile
        let currentUser
        if (this.user.email !== newEmail) {
          if (password) {
            currentUser = await this.reauthenticateUser(password)
          } else {
            currentUser = firebaseAuth.currentUser
          }

          // Update user email
          await updateEmail(currentUser, newEmail)
          // Send email verification
          await sendEmailVerification(currentUser)
        }
        // Update user profile
        await setFirebaseDocument('users', docID,
          {
            name: this.clone.name.trim(),
            phone: this.clone.phone,
            email: this.clone.email,
            avatar: this.clone.avatar
          })
        this.user = cloneDeep(this.clone)
        globalStore.openNotify(
          'success',
          'Success',
          'Your user profile has been updated.'
        )
      } catch (error) {
        console.error(error)
      }
    },

    // unsubscribe from listeners on auth state change
    unsubscribe () {
      console.log('unsubscribe user')
      this.listeners.forEach(fn => fn())
    }

  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
