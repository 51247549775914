<script setup>
import { useRouter } from 'vue-router'
import { Browser } from '@capacitor/browser'

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  },
  current: {
    type: Boolean,
    default: false
  }

})

const emit = defineEmits(['close'])
const router = useRouter()

const navItemStyle = 'group flex gap-x-3 rounded-md p-2 text-base/6 lg:text-sm/6 font-semibold'
const navIsCurrentStyle = 'bg-gray-600/10 text-gray-900 dark:bg-white/10 dark:text-white'
const navIsNotCurrentStyle = 'text-gray-700 dark:text-gray-300 hover:text-gray-800 hover:bg-gray-600/10 dark:hover:text-white dark:hover:bg-white/10'

function handleLinkClick (item) {
  if (item?.external) {
    Browser.open({ url: item?.href })
  } else {
    router.push(item?.href)
  }
  emit('close')
}
</script>

<template>
  <div
    v-if="props?.item?.divider"
    class="py-4"
  >
    <div class="border-t border-gray-900/10 dark:border-white/10" />
  </div>

  <button
    v-else
    :class="[props?.current ? navIsCurrentStyle : navIsNotCurrentStyle, navItemStyle, 'w-full']"
    type="button"
    @click="handleLinkClick(props?.item)"
  >
    <component
      :is="props?.item?.icon"
      :class="['h-6 w-6 shrink-0']"
      aria-hidden="true"
    />
    {{ props?.item?.name }}
  </button>
</template>
