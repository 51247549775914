import { acceptHMRUpdate, defineStore } from 'pinia'
import { getLiveFirebaseSubcollection } from '@/apis'
import { useUserStore } from '@/stores/useUserStore.js'

export const useLeadStore = defineStore('leads', {
  state: () => ({
    leads: new Map(),
    lead: {},
    listeners: [],
    sortBy: 'createdAt',
    sortByStatus: 'all'
  }),

  getters: {
    sortedLeads (state) {
      const array = Array.from(state.leads.values()) || []
      if (state.sortBy === 'name') {
        return array.sort((a, b) => {
          if (a.contact.name > b.contact.name) return -1
          if (a.contact.name < b.contact.name) return 1
          else return 0
        })
      }
      if (state.sortBy === 'createdAt') {
        return array.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      }
    },

    leadsByStatus (state) {
      const leads = state.sortedLeads
      return {
        all: leads,
        'new': leads.filter((item) => item.status === 'new'),
        'open': leads.filter((item) => item.status === 'open'),
        closed: leads.filter((item) => item.status === 'closed')
      }
    }
  },

  actions: {
    fetchLead (uid) {
      this.lead = this.leads.get(uid)
      return this.lead
    },

    /**
     * Asynchronously fetches support tickets from the Firebase database and updates the corresponding state object.
     * @async
     * @function
     * @param {string} docID - The id of the document to fetch. If not provided, the currently authenticated user's
     * id will be used.
     * @returns {undefined}
     * @throws {Error} Throws an error if there was a problem fetching the support tickets.
     */

    fetchLeads (docID) {
      const userStore = useUserStore()
      const companyId = userStore.user.company_uid || docID

      const unsubscribe = getLiveFirebaseSubcollection('companies', companyId, 'leads', (data) => {
        // loop through the keys in the document
        Object.keys(data).forEach(key => {
          // loop through the objects in the key
          Object.keys(data[key]).forEach(uid => {
            // add the object to the new object using the ID as the key
            this.leads.set(uid, data[key][uid])
          })
        })
      })
      this.listeners.push(unsubscribe)
    },

    /**
     * Assigns arrays from a documents object to corresponding fields in the current object.
     *
     * @function
     * @param {Object} documents - An object containing arrays of data to assign to fields in the current object. Each
     *   array should be keyed by the field name.
     * @returns {undefined}
     */
    assignArrays (documents) {
      this.leads = documents?.leads || []

    },

    /**
     * Unsubscribes all firebase listeners added to the current store array
     * @function
     * @returns {undefined}
     */
    unsubscribe () {
      this.listeners.forEach(fn => fn())
    }

  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLeadStore, import.meta.hot))
}
