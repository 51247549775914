import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'

/**
 * Registers all Vue components in the "base" and "global" directories as global components.
 * Component names are derived from file names, with the first letter capitalized and "Base" or "Global" prefixed.
 * @param {import('vue').App} app - The Vue app instance to register components on.
 */
export default function install (app) {
  // Base components
  const baseFiles = import.meta.glob('@/components/base/*.vue', { eager: true })
  Object.entries(baseFiles).forEach(([path, m]) => {
    const componentName = upperFirst(camelCase(path.split('/').pop().replace(/\.\w+$/, '')))
    app.component(`Base${componentName}`, m.default)
  })
}
